<template>
  <div class="">
    <img src="../assets/CSWT-1.jpg" class="w-100 rounded mb-3" />
    <div>
      <div>
        <div class="text-center my-3 main-heading" id="about-us">
          CSWT INDORE
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center mb-4"
        >
          <p class="col-12 px-3 mb-3">
            CSWT raised on 15th June 1963. First command – in 1930 Maharaja
            Tukoji Rao Holkar of Indore commissioned this campus for stationing
            his ‘Ist Cavalry’ and ‘Topkhana’ unit which had approximately 100
            Horses and a Dozen Guns . The cavalry unit was disbanded in 1938 but
            the guns were retained for ceremonial purposes. Soon after in 1940
            Maharaja of Indore established a training centre for recruits at
            this location and christened the same as ‘Indore Training Centre’.
            After independence, these assets were transferred to police
            department of erstwhile Madhya Bharat state and the Institution was
            renamed as ‘Police Training School’ which trained Constables comma
            Head Constables comma Sub Inspectors comma Inspectors and Dy SPs.
          </p>
          <p class="col-12 px-3 mb-3">
            The PTS continued to function as before under Madhya Pradesh Police
            after the reorganization of states. In order to ensure that police
            forces of various states of young independent India are trained on
            modern weapons and are enabled to deal with armed insurgents, anti
            –social elements etc comma Union Government re-christened this
            institute as&nbsp;<strong
              >“Central School Of Weapons &amp; Tactics”</strong
            >&nbsp;on 15 June 1963 under the aegis of Intelligence Bureau (IB).
            Shri K F Rustamji comma the first Director General of BSF comma had
            this institution transferred to BSF on 01 June 1966. First – command
            shri B. A Sharma comma IPS.
          </p>
        </div>
      </div>
      <div>
        <div class="text-center my-3 main-heading">CSWT AT A GLANCE</div>
        <div class="mb-4">
          <h5 class="text-center mb-3" id="history">HISTORY</h5>
          <p>
            The Holkars, the then rulers of Indore State, had selected this
            campus in 1930 for keeping their ‘Ist Cavalry’ and ‘Topkhana’ units
            comprising approximately 100 horses and about a dozen guns.In 1938,
            this Cavalry unit was disbanded but the guns were retained for
            ceremonial purposes like presenting gun salute to visiting
            dignitaries and for display during ‘Dushehra’ celebrations. After
            independence, all assets were transferred to the state police of
            erstwhile Madhya Bharat, which established its ‘Police Training
            School’ here for training Constables, Head constable, Sub-
            Inspectors, Inspectors and Dy SPs. This was taken over by Madhya
            Pradesh police after re-organisation of states in 1956.
          </p>
          <p>
            Central school of Weapons and Tactics – a national level training
            institution was conceived as a project of collaboration between the
            Govt. of India and the State of Madhya Pradesh. The School was
            formally inaugurated on 15 Jun 1963 and was subsequently taken over
            by the BSF on 1st Jun 1966. The need for establishing an institution
            like the CSWT was felt with a view that the Indian policemen need to
            be proficient in the use of weapons to deal with challanges posed by
            armed criminals, dacoits and secessionist. It was also visioned that
            this institution will provide for an ideal platform to policemen,
            drawn from different states and CPOs to interact and share their
            experience.
          </p>
          <p>
            From the very inception, this School has maintained high standards
            of training particularly in handling of arms. The school has paid
            special attention to train the police personnel on correct
            application of tactics in dealing with the elusive outlawed armed
            gangs and extremists. CSWT has been declared a “Centre of
            Excellence” by the Govt. of India. It also has been certified for
            ISO-9001:2008 &amp; 14001:2004 standards.
          </p>
          <h5 class="text-center mb-3">CAMPUS</h5>
          <p>
            CSWT main campus is spread over 162 acres of land in close proximity
            of Indore Airport. It is laid out into well defined training area,
            administrative area, hostels and bachelor quarters and family
            accommodation for the faculty and staff. Besides providing for basic
            amenities a number of institutions which cater for day to day
            necessities, entertainment, sports, social interactions are also
            located in this campus. It provides for harmonious environments for
            training and living.
          </p>
          <p>
            In addition, the CSWT has three more campuses viz. Bijasan campus
            (approx. 2 kms) and Budhania campus (approx. 8 kms) which facilitate
            conduct of out-door training and Reoti Ranges Complex where modern
            and state of the art Small Arms Firing Ranges are located.
          </p>
          <p>&nbsp;</p>
          <h5 class="text-center mb-3">CLIMATE</h5>
          <p>&nbsp;</p>
          <p>
            By virtue of its location in the Malwa plateau, Indore has a
            moderate climate. Except for the summers when the mercury hovers
            around 40 degree C, the climate is generally pleasant. Monsoon
            arrive by middle of June and soon the parched land wraps it self
            with green carpet. Winters are moderate and at present the is the
            best season for work and relaxation.
          </p>
          <p>&nbsp;</p>
          <h5 class="text-center mb-3" id="activities">TRAINING ACTIVITIES</h5>
          <p>&nbsp;</p>
          <p>
            The School conducts a number of in-service courses specially
            designed for Officers, Subordinate Officers and Other Ranks. These
            courses are subscribed by BSF, other CPOs and State Police
            Organisations. The School also trains newly recruited SI (DE) &amp;
            Constables of BSF through their foundation training period. In
            addition, a number of Seminars, Workshops and Vertical Interactions
            are also organized at CSWT for BSF, other CPOs and State Police
            Organisations as and when assigned by the BPR&amp;D and BSF HQrs.
          </p>
          <p>&nbsp;</p>
          <p>
            The institution focuses on “Training of Trainers” in the field of
            Weapons, Map Reading and Snipers, who, in turn, undertake
            responsibility of training the rank and file of BSF in these
            specialized skills. The School also trains Armourers for the BSF and
            other CPOs and SPOs at different levels of skills. Weapons and
            Tactics Course subscribed by BSF, other CPOs and primarily State
            Police Organizations aims at training instructors in the field of
            Weapons and Counter Insurgency Operations. IPS (Probationers) also
            undergo a short training programme on Weapons and Tactics. On an
            average approximately 1100 BSF personnel and 400 personnel drawn
            from CPOs and SPOs and foreign nationals undergo various training
            programmes at CSWT every year. Till the end of training year
            2010-11, 44079 number of trainees have undergone various training
            programmes at CSWT.
          </p>
          <p>&nbsp;</p>
          <h5 class="text-center mb-3">TRAINING INFRASTRUCTURE</h5>
          <p>&nbsp;</p>
          <p>
            Over a period of time CSWT has created excellent infrastructure for
            training. It has also acquired a wide variety of state of the art
            training aids. CSWT has published a number of training pamphlets/
            précis which not only cater for the requirements of trainees but are
            in great demand with CPOs and State Police Organizations. The School
            has also acquired a rich library of training films which have been
            customized to its needs. Most notable achievement has been
            production of Computer Based Training (CBT) packages which help the
            trainers and the trainees to explain and assimilate the functional
            aspects of weapon systems presently in use. These CBT packages are
            in great demand within the BSF and outside i.e CPOs and SPOs and
            thus facilitating training of men in khaki throughout the country.
          </p>
          <p>&nbsp;</p>
          <h5 class="text-center mb-3"><b>FACULTY</b></h5>
          <p>&nbsp;</p>
          <p>
            Only the best among those who undergo training programmes at CSWT
            are picked up for faculty assignments. The faculty members also add
            to their competence as they further attend trg programmes at other
            specialised institutions in the country. The faculty has rich
            professional experience in the field and many of them excelled in
            actual operations. All training courses are conducted under the
            direction and supervision of a designated officer who is supported
            by a team of officers, Subordinate Officers and Head Constables
            (Squad Instructors). This dedicated faculty is further assisted by
            the faculty drawn from other wings and is supported by a selected
            contingent of demo troops.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomePage",
  data() {
    return {};
  },
};
</script>
<style scoped>
.main-heading {
  font-size: 2rem;
}
</style>