<template>
  <div class="container mt-5">
    <div
      v-if="error"
      class="login-error border border-danger rounded p-2 mb-3 text-danger font-w500"
    >
      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
      {{ error }}
    </div>
    <b-form
      class="container justify-content-center mx-4 mb-5 w-auto"
      @submit.prevent="login"
      @reset="onReset"
    >
      <b-form-group
        id="login-email-id"
        class="pb-3"
        label="Login ID"
        label-for="login-email"
      >
        <b-form-input
          id="login-email"
          v-model="email"
          type="email"
          placeholder="Enter Login ID"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="login-password"
        class="pb-3"
        label="Your Password"
        label-for="login-pass"
      >
        <b-form-input
          id="login-pass"
          v-model="password"
          type="password"
          placeholder="Enter password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" class="bg-brand mb-2">Login</b-button>
      <b-button class="btn btn-link mx-2" @click="forgotPassword"
        >Forgot Password</b-button
      >
      <!-- <b-button class="ml-1" type="reset">Reset</b-button> -->
      <!-- <div>
        <router-link to="/signup">New on CSWT@BSF? Signup</router-link>
      </div> -->
    </b-form>
  </div>
</template>
<script>
import { BFormInput, BFormGroup, BForm, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "LoginPage",
  components: { BFormInput, BFormGroup, BForm, BButton },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.status,
      error: (state) => state.error,
      user: (state) => state.user,
      userStatus: (state) => state.userStatus,
    }),
  },
  methods: {
    ...mapActions(["loginAction"]),
    async login() {
      const user = {
        email: this.email,
        password: this.password,
      };
      await this.loginAction(user);
    },
    forgotPassword() {
      this.$router.push("/forgotPassword");
    },
    onReset() {
      this.email = "";
      this.password = "";
    },
  },
  watch: {
    userStatus(val) {
      if (val === "success") {
        console.log("userStatus watcher", this.user);
        if (this.user && (!this.user.phone || this.user.phone == "")) {
          this.$router.push("/linkPhonenumber");
        } else {
          this.$router.push("/results");
        }
      }
    },
  },
};
</script>
<style scoped>
.login-error {
  margin-left: 2.2rem !important;
  margin-right: 2.2rem !important;
}
</style>