<template>
  <div>
    <div
      v-if="passwordError"
      class="login-error border border-danger rounded p-2 mb-3 text-danger font-w500"
    >
      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
      {{ passwordError }}
    </div>
    <b-form @submit.prevent="onResetPassowrd" class="w-25">
      <b-form-group
        id="user-new-password-id"
        class="pb-3"
        label="New Password"
        label-for="user-new-password"
      >
        <b-form-input
          id="user-new-password"
          v-model="newPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="user-confirm-new-password-id"
        class="pb-3"
        label="Confirm Password"
        label-for="user-confirm-new-password"
      >
        <b-form-input
          id="user-confirm-new-password"
          v-model="confirmPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" class="bg-brand mb-2"
        >Submit
        <i
          v-show="status == 'loading'"
          class="fa-solid fa-spinner fa-spin pl-3"
        ></i
      ></b-button>
      <p v-if="error" class="text-danger mb-0 error-message">
        <i class="fa-solid fa-triangle-exclamation pr-3"></i>{{ error }}
      </p>
    </b-form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm } from "bootstrap-vue";
export default {
  name: "ResetPasswordPage",
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      passwordError: null,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.status,
      error: (state) => state.error,
    }),
    checkIfPasswordsMatch() {
      return this.newPassword === this.confirmPassword;
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
  },
  methods: {
    ...mapActions([
      "resetPassword",
      "resetStatesOnPasswordReset",
      "logoutAction",
    ]),
    async onResetPassowrd() {
      console.log("checkIfPasswordsMatch", this.checkIfPasswordsMatch);
      if (this.checkIfPasswordsMatch) {
        console.log("onResetPassowrd", this.newPassword);
        this.passwordError = null;
        await this.resetPassword(this.newPassword);
        this.logoutAction();
        this.$router.push("/login");
      } else {
        this.passwordError = `Passwords don't match. Try again.`;
      }
    },
  },
};
</script>
<style scoped>
.error-message {
  font-size: 12px;
}
</style>