<template>
  <div class="user-profile">
    <!-- <div class="d-flex justify-content-end align-items-center">
      <b-button class="bg-brand mb-2" @click="openResetPasswordModal"
        >Reset Password</b-button
      >
    </div> -->
    <ResetPassword
      ref="resetPasswordModal"
      id="reset-password-modal"
      :title="'Reset Password'"
      @onOk="hideResetPasswordModal"
      @onCancel="hideResetPasswordModal"
    />
    <div
      v-if="updateUserError || updateProfilePhoneNumberError"
      class="login-error border border-danger rounded p-2 mb-3 text-danger font-w500 login-error"
    >
      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
      {{ updateUserError || updateProfilePhoneNumberError }}
    </div>
    <b-form
      class="container justify-content-center m-5 w-auto"
      @submit.prevent="updateProfile"
    >
      <b-form-group
        id="full-name-id"
        class="pb-3"
        label="Full Name"
        label-for="full-name"
      >
        <b-form-input
          id="full-name"
          v-model="user.name"
          type="text"
          placeholder="Enter name"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="user-phone-id"
        class="pb-3"
        label="Mobile Number"
        label-for="user-phone"
      >
        <b-form-input
          id="user-phone"
          v-model="user.phone"
          type="tel"
          placeholder="Enter mobile number"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="login-email-id"
        class="pb-3"
        label="Login ID"
        label-for="login-email"
        description="Login ID can't be changed. This is a primary username."
      >
        <b-form-input
          id="login-email"
          v-model="user.email"
          type="email"
          placeholder="Enter Login ID"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-if="!isUserOffice"
        id="user-irla-no-id"
        class="pb-3"
        label="Your IRLA Number"
        label-for="user-irla-no"
      >
        <b-form-input
          id="user-irla-no"
          v-model="user['IRLA/Regimental Number']"
          type="text"
          placeholder="Enter IRLA Number"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-if="!isUserOffice"
        id="battalion-id"
        class="pb-3"
        label="Unit"
        label-for="battalion"
      >
        <b-form-input
          id="battalion"
          v-model.number="user['Battalion']"
          type="text"
          placeholder="Enter Unit"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="rank-id" class="pb-3" label="Rank" label-for="rank">
        <b-form-input
          id="rank"
          v-model="user['Rank']"
          type="text"
          placeholder="Enter Rank"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-if="!isUserOffice"
        id="course_title-id"
        class="pb-3"
        label="Course"
        label-for="course_title"
      >
        <b-form-input
          id="course_title"
          v-model="user.course.title"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" class="bg-brand mb-2" id="update-profile-button"
        >Update Profile <span v-if="updateUserStatus === 'loading'">...</span>
      </b-button>
      <div id="recaptcha-container-phone-number-update"></div>
    </b-form>
  </div>
</template>
<script>
import { BFormInput, BFormGroup, BForm, BButton, BCard } from "bootstrap-vue";
import { mapState, mapActions, mapGetters } from "vuex";
import ResetPassword from "@/components/modal/ResetPassword.vue";

export default {
  name: "UserProfile",
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCard,
    ResetPassword,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      updateUserStatus: (state) => state.updateUserStatus,
      updateUserError: (state) => state.updateUserError,
      updateProfilePhoneNumberStatus: (state) =>
        state.updateProfilePhoneNumberStatus,
      updateProfilePhoneNumberError: (state) =>
        state.updateProfilePhoneNumberError,
    }),
    ...mapGetters(["isUserOffice"]),
  },
  data() {
    return {
      currentUser: { ...this.user },
    };
  },
  methods: {
    ...mapActions([
      "editUserProfile",
      "updatePhoneNumberInAuth",
      "updatePhoneNumberCurrentUser",
    ]),
    async updateProfile() {
      if (this.currentUser.phone !== this.user.phone) {
        console.log(
          "User's phone is getting updated",
          this.currentUser.phone,
          this.user.phone
        );
        const { key, name, phone } = { ...this.user };
        if (this.user["Battalion"]) {
          await this.updatePhoneNumberCurrentUser({
            key,
            name,
            phone,
            Battalion: this.user["Battalion"],
            Rank: this.user["Rank"],
          });
        } else {
          await this.updatePhoneNumberCurrentUser({
            key,
            name,
            phone,
            Rank: this.user["Rank"],
          });
        }
      } else {
        const { key, name, phone } = { ...this.user };
        if (this.user["Battalion"]) {
          await this.editUserProfile({
            key,
            name,
            phone,
            Battalion: this.user["Battalion"],
            Rank: this.user["Rank"],
          });
        } else {
          await this.editUserProfile({
            key,
            name,
            phone,
            Rank: this.user["Rank"],
          });
        }
      }
    },
    hideResetPasswordModal() {
      this.$refs.resetPasswordModal.$refs.resetPasswordModal.hide();
    },
    openResetPasswordModal() {
      this.$refs.resetPasswordModal.$refs.resetPasswordModal.show();
    },
  },
  watch: {
    updateUserStatus(val) {
      console.log("updateUserStatus watcher", val);
    },
  },
};
</script>
<style scoped>
#course_title {
  cursor: not-allowed;
}
#course_title:focus {
  box-shadow: none;
}
#login-email {
  cursor: not-allowed;
}
#login-email:focus {
  box-shadow: none;
}
.login-error {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
</style>