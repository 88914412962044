<template>
  <div class="container mt-5">
    <div
      v-if="error"
      class="login-error border border-danger rounded p-2 mb-3 text-danger font-w500"
    >
      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
      {{ error }}
    </div>
    <b-form
      class="container justify-content-center mx-4 mb-5 w-auto"
      @submit.prevent="login"
      @reset="onReset"
    >
      <b-form-group
        id="login-phone-number-id"
        class="pb-3"
        label="Phone Number"
        label-for="login-phone-number"
        description="Enter your 10 digit phone number associated with the account."
      >
        <b-form-input
          id="login-phone-number"
          v-model="phone"
          type="text"
          placeholder="Enter phone number"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" class="bg-brand mb-2">Submit</b-button>
      <div class="mt-4">
        <p tabindex="-1" class="form-text text-danger contact-admin-text">
          ** In case if you don't have a phone number attached with your account
          then contact admin to link you phone number.
        </p>
        <p tabindex="-1" class="form-text text-danger contact-admin-text">
          ** यदि आपके खाते के साथ कोई फ़ोन नंबर जुड़ा हुआ नहीं है, तो अपना फ़ोन
          नंबर लिंक करने के लिए Admin से संपर्क करें
        </p>
      </div>

      <div id="recaptcha-container-login"></div>
    </b-form>
  </div>
</template>
<script>
import { BFormInput, BFormGroup, BForm, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "ForgotPasswordPage",
  components: { BFormInput, BFormGroup, BForm, BButton },
  data() {
    return {
      phone: "",
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.status,
      error: (state) => state.error,
    }),
  },
  methods: {
    ...mapActions(["checkIfUserExists"]),
    async login() {
      const user = {
        phone: `+91${this.phone}`,
      };
      await this.checkIfUserExists(user);
    },
    onReset() {
      this.phone = "";
    },
  },
  watch: {
    status(val) {
      if (val == "success") {
        this.$router.push("/resetPassword");
      }
    },
  },
};
</script>
<style scoped>
.login-error {
  margin-left: 2.2rem !important;
  margin-right: 2.2rem !important;
}
.contact-admin-text {
  font-size: 16px;
  font-weight: 600;
}
</style>