import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import firebase from "firebase/compat/app";
import store from "@/store";

import HomePage from "@/components/HomePage";
import Login from "@/components/auth/Login";
import ForgotPassword from "@/components/auth/ForgotPassword";
import ResetPassword from "@/components/auth/ResetPassword";
import LinkPhonenumber from "@/components/auth/LinkPhonenumber";

import UserProfile from "@/components/UserProfile";
import Courses from "@/components/Courses";
import Tests from "@/components/Tests";
import Results from "@/components/Results";

import Notices from "@/components/Notices";
import Users from "@/components/Users";

import History from "@/components/History";

const routes = [
  {
    path: "/",
    name: "homePage",
    component: HomePage,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    meta: {
      authRequired: true,
    },
    component: ResetPassword,
  },
  {
    path: "/linkPhonenumber",
    name: "LinkPhonenumber",
    meta: {
      authRequired: true,
    },
    component: LinkPhonenumber,
  },
  {
    path: "/profile",
    name: "UserProfile",
    meta: {
      authRequired: true,
    },
    component: UserProfile,
  },
  {
    path: "/courses",
    name: "Courses",
    meta: {
      authRequired: true,
    },
    component: Courses,
  },
  {
    path: "/tests",
    name: "Tests",
    meta: {
      authRequired: true,
    },
    component: Tests,
  },
  {
    path: "/results",
    name: "Results",
    meta: {
      authRequired: true,
    },
    component: Results,
  },
  {
    path: "/users",
    name: "Users",
    meta: {
      authRequired: true,
    },
    component: Users,
  },
  {
    path: "/history",
    name: "History",
    meta: {
      authRequired: true,
    },
    component: History,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("initialiseStore");
  if (
    to.matched.some((record) => record.meta.authRequired) ||
    !to.matched.length
  ) {
    if (firebase.auth().currentUser || store.state.user) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

export default router;
