import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import firebase from "firebase/compat/app";
import {
  db,
  auth,
  admin,
  functions,
  httpsCallable,
  RecaptchaVerifier,
  storage,
  ref,
  getDownloadURL,
} from "@/firebaseDb";

const deleteUserFromAuth = httpsCallable(functions, "deleteUserFromAuth");
const updateUserPhoneNumberInAuth = httpsCallable(
  functions,
  "updateUserPhoneNumberInAuth"
);
const checkIfUserExistsByPhoneNumber = httpsCallable(
  functions,
  "checkIfUserExistsByPhoneNumber"
);

const state = {
  user: null,
  userStatus: null,
  userError: null,
  studentStatus: "",
  studentError: null,
  status: null,
  error: null,
  tests: [],
  testsStatus: "",
  testsError: null,
  students: [],
  studentsStatus: "",
  studentsError: null,
  courses: [],
  coursesStatus: "",
  coursesError: null,
  isTestExist: null,
  editCourseStatus: "",
  editCourseError: null,
  addNewTestStatus: "",
  addNewTestError: null,
  addStudentStatus: "",
  addStudentError: null,
  updateUserStatus: "",
  updateUserError: null,
  addNewCourseStatus: "",
  addNewCourseError: null,
  updateTestStatus: "",
  updateTestError: null,
  currentCourse: null,
  studentForCalculation: null,
  updateUserPhoneNumberStatus: "",
  updateUserPhoneNumberError: null,
  history: [],
  historyStatus: "",
  historyError: null,
  developerProfilePhoto: null,
  updateProfilePhoneNumberStatus: "",
  updateProfilePhoneNumberError: null,
  updatePhoneNumberInAuthStatus: "",
  updatePhoneNumberInAuthError: null,
};

const mutations = {
  initialiseStore(state) {
    if (localStorage.getItem("user")) {
      state.user = JSON.parse(localStorage.getItem("user"));
    }
  },
  resetStore(state) {
    if (localStorage.getItem("user")) {
      localStorage.removeItem("user");
    }
    state.user = null;
  },
  setUser(state, payload) {
    localStorage.setItem("user", JSON.stringify(payload));
    state.user = payload;
  },
  setUserStatus(state, payload) {
    state.userStatus = payload;
  },
  setUserError(state, payload) {
    state.userError = payload;
  },
  getStudentStatus(state, payload) {
    state.studentStatus = payload;
  },
  getStudentError(state, payload) {
    state.studentError = payload;
  },
  removeUser(state) {
    state.user = null;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setTests(state, payload) {
    state.tests = payload;
  },
  setStudents(state, payload) {
    state.students = payload;
  },
  setStudentsStatus(state, payload) {
    state.studentsStatus = payload;
  },
  setStudentsError(state, payload) {
    state.studentsError = payload;
  },
  setCourses(state, payload) {
    state.courses = payload;
  },
  setCoursesStatus(state, payload) {
    state.coursesStatus = payload;
  },
  setCoursesError(state, payload) {
    state.coursesError = payload;
  },
  setTestExist(state, payload) {
    state.isTestExist = payload;
  },
  setAddNewTestStatus(state, payload) {
    state.addNewTestStatus = payload;
  },
  setAddNewTestError(state, payload) {
    state.addNewTestError = payload;
  },
  setAddStudentStatus(state, payload) {
    state.addStudentStatus = payload;
  },
  setAddStudentError(state, payload) {
    state.addStudentError = payload;
  },
  setUpdateUserStatus(state, payload) {
    state.updateUserStatus = payload;
  },
  setUpdateUserError(state, payload) {
    state.updateUserError = payload;
  },
  setTestsStatus(state, payload) {
    state.testsStatus = payload;
  },
  setTestsError(state, payload) {
    state.testsError = payload;
  },
  setAddNewCourseStatus(state, payload) {
    state.addNewCourseStatus = payload;
  },
  setAddNewCourseError(state, payload) {
    state.addNewCourseError = payload;
  },
  setEditCourseStatus(state, payload) {
    state.editCourseStatus = payload;
  },
  setEditCourseError(state, payload) {
    state.editCourseError = payload;
  },
  setUpdateTestStatus(state, payload) {
    state.updateTestStatus = payload;
  },
  setUpdateTestError(state, payload) {
    state.updateTestError = payload;
  },
  setCurrentCourse(state, payload) {
    state.currentCourse = payload;
  },
  setStudentForCalculation(state, payload) {
    state.studentForCalculation = payload;
  },
  setUpdateUserPhoneNumberStatus(state, payload) {
    state.updateUserPhoneNumberStatus = payload;
  },
  setUpdateUserPhoneNumberError(state, payload) {
    state.updateUserPhoneNumberError = payload;
  },
  setHistory(state, payload) {
    state.history = payload;
  },
  setHistoryStatus(state, payload) {
    state.historyStatus = payload;
  },
  setHistoryError(state, payload) {
    state.historyError = payload;
  },
  setDeveloperProfileUrl(state, payload) {
    state.developerProfilePhoto = payload;
  },
  setUpdateProfilePhoneNumberStatus(state, payload) {
    state.updateProfilePhoneNumberStatus = payload;
  },
  setUpdateProfilePhoneNumberError(state, payload) {
    state.updateProfilePhoneNumberError = payload;
  },
  setUpdatePhoneNumberInAuthStatus(state, payload) {
    state.updatePhoneNumberInAuthStatus = payload;
  },
  setUpdatePhoneNumberInAuthError(state, payload) {
    state.updatePhoneNumberInAuthError = payload;
  },
};
const actions = {
  async signupAction({ dispatch, commit, state, getters }, payload) {
    console.log("signupAction", payload);
    commit("setAddStudentStatus", "loading");
    await firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then((response) => {
        console.log("createUserWithEmailAndPassword", response.user.uid);

        // if (payload.role && ["god", "admin", "office"].includes(payload.role)) {
        //   db.collection("students")
        //     .doc(response.user.uid)
        //     .set({
        //       email: response.user.email,
        //       name: payload.name,
        //       phone: payload.phone,
        //       role: payload.role || "user",
        //       key: response.user.uid,
        //       "IRLA/Regimental Number": payload["IRLA/Regimental Number"],
        //       Rank: payload["Rank"],
        //       Battalion: payload["Battalion"],
        //     });
        //   commit("setAddStudentStatus", "success");
        // } else {
        console.log("state.tests", state.tests);
        let allTests = [];
        for (let test of state.tests.filter(
          (t) => t.course && t.course.id == payload.course.id
        )) {
          let testDetails = { ...test };
          testDetails.obtained_marks = 0;
          if (testDetails.subtests_available) {
            let subtests = [];
            for (let subtest of testDetails.subtests) {
              subtests.push({ ...subtest, obtained_marks: 0 });
            }
            testDetails.subtests = [...subtests];
          }
          allTests.push(testDetails);
        }
        console.log("allTests", allTests, state.currentCourse);
        db.collection("students")
          .doc(response.user.uid)
          .set({
            email: response.user.email,
            name: payload.name,
            phone: payload.phone,
            role: payload.role || "user",
            key: response.user.uid,
            "IRLA/Regimental Number": payload["IRLA/Regimental Number"],
            Rank: payload["Rank"],
            Battalion: payload["Battalion"],
            tests: [...allTests],
            course: payload.course,
          });
        commit("setAddStudentStatus", "success");
        // }
        if (state.currentCourse) {
          dispatch("getStudents", {
            course: state.currentCourse,
            isCourseSelected: true,
          });
        } else {
          if (getters.isUserGod) {
            dispatch("getStudents", {
              isCourseSelected: false,
            });
          } else if (getters.isUserAdmin || getters.isUserOffice) {
            dispatch("getStudents", {
              isCourseSelected: false,
              wing: state.user.wing,
            });
          }
        }
      })
      .catch((error) => {
        commit("setAddStudentStatus", "failure");
        commit("setAddStudentError", error.message);
      });
  },
  async signupActionAdmin({ commit, state }, payload) {
    console.log("signupActionAdmin", payload);
    commit("setAddStudentStatus", "loading");
    await firebase
      .auth()
      .createUserWithEmailAndPassword("developer@cswt.in", "developer@cswt")
      .then((response) => {
        console.log("createUserWithEmailAndPassword", response, payload);

        db.collection("students").doc(response.user.uid).set({
          email: "developer@cswt.in",
          name: "Developer",
          role: "god",
          key: response.user.uid,
          Rank: "AC",
        });
        commit("setAddStudentStatus", "success");
      })
      .catch((error) => {
        commit("setAddStudentStatus", "failure");
        commit("setAddStudentError", error.message);
      });
  },
  async loginAction({ dispatch, commit }, payload) {
    commit("setStatus", "loading");
    commit("setError", null);
    await firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((response) => {
        console.log("loginAction", response.user);
        dispatch("getCurrentUser", response.user);
        commit("setStatus", "success");
      })
      .catch((error) => {
        commit("setError", error.message);
        commit("setStatus", "failure");
      });
  },
  async loginActionByPhoneNumber({ dispatch, commit }, payload) {
    commit("setStatus", "loading");
    commit("setError", null);
    console.log("loginActionByPhoneNumber", payload);
    window.recaptchaVerifierForPhoneNumber = new RecaptchaVerifier(
      "recaptcha-container-login",
      {},
      firebase.auth()
    );
    let appVerifier = window.recaptchaVerifierForPhoneNumber;

    await firebase
      .auth()
      .signInWithPhoneNumber(payload.phone, appVerifier)
      .then((verificationObject) => {
        // Prompt the user to enter the verification code.

        console.log("VerificationID", verificationObject.verificationId);
        const verificationCode = window.prompt(
          "Please enter the verification code sent to your mobile phone:"
        );
        console.log("verificationCode", verificationCode);
        // Create a PhoneAuthCredential object using the verification ID and verification code.

        return verificationObject.confirm(verificationCode);
        // Link the phone number to the user's account with the PhoneAuthCredential object.
      })
      .then((response) => {
        console.log(
          "Phone number added to the user's account successfully.",
          response.user
        );
        dispatch("getCurrentUser", response.user);
        commit("setStatus", "success");
        // ...
      })
      .catch((error) => {
        // Handle error adding phone number.
        console.error("Error in adding phone", error);
        commit("setError", error.message);
        commit("setStatus", "failure");
      });
  },
  async logoutAction({ dispatch, commit }) {
    commit("setUserStatus", "loading");
    await firebase
      .auth()
      .signOut()
      .then(() => {
        commit("setUser", null);
        commit("setUserStatus", "success");
        commit("setUserError", null);
        commit("resetStore");
      })
      .catch((error) => {
        commit("setUserStatus", "failure");
        commit("setUserError", error.message);
      });
  },
  async resetPassword({ commit }, payload) {
    commit("setStatus", "loading");
    const user = firebase.auth().currentUser;
    console.log("resetPassword currentUser", user, payload);
    await user
      .updatePassword(payload)
      .then(function () {
        console.log("Successfully updated user");
        commit("setStatus", "success");
      })
      .catch(function (error) {
        commit("setStatus", "failure");
        commit("setError", error.message);
        console.log("Error updating user:", error);
      });
  },
  async checkIfUserExists({ dispatch, commit }, payload) {
    console.log("checkIfUserExists", payload);
    await checkIfUserExistsByPhoneNumber(payload)
      .then(() => {
        console.log("Phone number exists in Auth.");
        dispatch("loginActionByPhoneNumber", payload);
      })
      .catch((error) => {
        console.log("Error in checking user existence", error);
        console.log("User doesn't exist");
        commit(
          "setError",
          `This phone number is not linked to your account. Please contact admin to link your phone number and reset your password.`
        );
        // if (error.code === "auth/user-not-found") {
        //   console.log("User doesn't exist");
        //   commit("setError", error.message);
        // }
      });
  },
  getStudents({ commit, state }, payload) {
    console.log("getStudents initial", payload);
    commit("setStudentsStatus", "loading");
    if (payload && payload.isCourseSelected) {
      db.collection("students")
        .where("course.id", "==", payload.course.id)
        .where("role", "==", "user")
        .get()
        .then((querySnapshot) => {
          let students = [];
          querySnapshot.forEach((doc) => {
            students.push(doc.data());
          });
          console.log("getStudents", students);
          commit("setStudents", students);
          commit("setStudentsStatus", "success");
        })
        .catch((error) => {
          commit("setStudentsStatus", "failure");
          commit("setStudentsError", error.message);
        });
    } else if (payload.wing) {
      let studentMembersList = [];
      let officeMembersList = [];
      // (where("role", "==", "user") && where("course.wing", "==", "wing")) || (where("role", "==", "office") && && where("wing", "==", "wing"))
      db.collection("students")
        .where("course.wing", "==", payload.wing)
        .get()
        .then((querySnapshot) => {
          let students = [];
          querySnapshot.forEach((doc) => {
            students.push(doc.data());
          });
          console.log("getStudents", students);
          commit("setStudents", students);
          commit("setStudentsStatus", "success");
        })
        .catch((error) => {
          commit("setStudentsStatus", "failure");
          commit("setStudentsError", error.message);
        });
    } else {
      db.collection("students")
        .get()
        .then((querySnapshot) => {
          let students = [];
          querySnapshot.forEach((doc) => {
            students.push(doc.data());
          });
          console.log("getStudents", students);
          commit("setStudents", students);
          commit("setStudentsStatus", "success");
        })
        .catch((error) => {
          commit("setStudentsStatus", "failure");
          commit("setStudentsError", error.message);
        });
    }
  },
  getCurrentUser({ dispatch, commit }, payload) {
    commit("setUserStatus", "loading");
    const studentsRef = db.collection("students").doc(payload.uid);
    studentsRef
      .get()
      .then((doc) => {
        console.log("doc doc", doc.data());
        console.log(
          "getCurrentUser data",
          { ...doc.data(), key: payload.uid },
          payload
        );
        let student = {
          ...doc.data(),
          key: payload.uid,
        };
        if (payload.phoneNumber && payload.phoneNumber.length > 10) {
          student.phone = Number(payload.phoneNumber.slice(3));
        } else if (payload.phoneNumber) {
          student.phone = payload.phoneNumber;
        } else if (payload.phone) {
          student.phone = payload.phone;
        }
        console.log("Student phone number saved as - ", student);
        commit("setUser", student);
        commit("setUserStatus", "success");
        if (student.course) {
          dispatch("updateCurrentCourse", student.course);
        }
      })
      .catch((error) => {
        commit("setUserStatus", "failure");
        commit("setUserError", error.message);
      });
  },
  async getStudent({ dispatch, commit }, payload) {
    console.log("getStudent", payload);
    commit("getStudentStatus", "loading");
    const studentsRef = db.collection("students").doc(payload);
    await studentsRef
      .get()
      .then((doc) => {
        let student = {
          ...doc.data(),
          key: payload,
        };
        commit("getStudentStatus", "success");
        commit("setStudentForCalculation", student);
      })
      .catch((error) => {
        commit("getStudentStatus", "failure");
        commit("getStudentError", error.message);
      });
  },
  editUser({ dispatch, commit }, payload) {
    commit("setStudentsStatus", "loading");
    console.log("editUser", payload);
    db.collection("students")
      .doc(payload.key)
      .set(payload)
      .then(() => {
        commit("setStudentsStatus", "success");
        commit("setStudentForCalculation", null);
      })
      .catch((error) => {
        commit("setStudentsStatus", "failure");
        commit("setStudentForCalculation", null);
        commit("setStudentsError", error.message);
      });
  },
  editUserInList({ dispatch, commit, getters, state }, payload) {
    commit("setStudentsStatus", "loading");
    console.log("editUserInList", payload);
    db.collection("students")
      .doc(payload.key)
      .set(payload)
      .then(() => {
        commit("setStudentsStatus", "success");
        commit("setStudentForCalculation", null);
        if (getters.isUserGod) {
          dispatch("getStudents", { isCourseSelected: false });
        } else if (getters.isUserAdmin || getters.isUserOffice) {
          dispatch("getStudents", {
            isCourseSelected: false,
            wing: state.user.wing,
          });
        }
      })
      .catch((error) => {
        commit("setStudentsStatus", "failure");
        commit("setStudentForCalculation", null);
        commit("setStudentsError", error.message);
      });
  },
  editUserProfile({ dispatch, commit }, payload) {
    commit("setUpdateUserStatus", "loading");
    console.log("editUserProfile", payload);
    db.collection("students")
      .doc(payload.key)
      .update({
        ...payload,
      })
      .then(() => {
        commit("setUpdateUserStatus", "success");
        dispatch("getCurrentUser", { uid: payload.key, ...payload });
      })
      .catch((error) => {
        commit("setUpdateUserStatus", "failure");
        commit("setUpdateUserError", error.message);
      });
  },
  updatePhoneNumberInAuth({ dispatch, commit }, payload) {
    commit("setUpdatePhoneNumberInAuthStatus", "loading");
    commit("setUpdatePhoneNumberInAuthError", null);
    console.log("updatePhoneNumberInAuth", payload);
    window.recaptchaVerifierPhoneNumber = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      firebase.auth()
    );
    let appVerifier = window.recaptchaVerifierPhoneNumber;

    // const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
    //   verificationId,
    //   verificationCode
    // );

    // firebase
    //   .auth()
    //   .currentUser.updatePhoneNumber(appVerifier, payload.phone)
    //   .then(function () {
    //     console.log("Phone number updated successfully");
    //     return firebase.auth().currentUser.getIdToken();
    //   })
    //   .then((credential) => {
    //     firebase.auth().currentUser.reauthenticateWithCredential(credential);
    //   })
    //   .catch(function (error) {
    //     console.log("Error updating phone", error);
    //   });
    console.log("Has recaptcha rendered?");
    firebase
      .auth()
      .signInWithPhoneNumber(payload.phone, appVerifier)
      .then((verificationObject) => {
        // Prompt the user to enter the verification code.

        console.log("VerificationID", verificationObject.verificationId);
        const verificationCode = window.prompt(
          "Please enter the verification code sent to your mobile phone:"
        );
        console.log("verificationCode", verificationCode);
        // Create a PhoneAuthCredential object using the verification ID and verification code.
        // const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
        //   verificationId,
        //   verificationCode
        // );

        // console.log(
        //   "phoneCredential",
        //   phoneCredential,
        //   firebase.auth().currentUser
        // );
        return firebase.auth.PhoneAuthProvider.credential(
          verificationObject.verificationId,
          verificationCode
        );
        // Link the phone number to the user's account with the PhoneAuthCredential object.
      })
      .then((phoneCredential) => {
        console.log(
          "phoneCredential",
          phoneCredential,
          firebase.auth().currentUser
        );
        const currentUser = firebase.auth().currentUser;
        return currentUser.linkWithCredential(phoneCredential);
      })
      .then(() => {
        console.log("Phone number added to the user's account successfully.");
        dispatch("editUserProfile", { ...payload });
        commit("setUpdatePhoneNumberInAuthStatus", "success");
        // ...
      })
      .catch((error) => {
        // Handle error adding phone number.
        console.error("Error in adding phone", error);
        commit("setUpdatePhoneNumberInAuthStatus", "failure");
        commit("setUpdatePhoneNumberInAuthError", error);
      });
  },
  updatePhoneNumberCurrentUser({ dispatch, commit }, payload) {
    console.log("updatePhoneNumberCurrentUser", payload);
    commit("setUpdateProfilePhoneNumberStatus", "loading");

    window.recaptchaVerifierPhoneNumberCurrentUser = new RecaptchaVerifier(
      "recaptcha-container-phone-number-update",
      {},
      firebase.auth()
    );
    let appVerifier = window.recaptchaVerifierPhoneNumberCurrentUser;

    let phoneNumberWithCountryCode = `+91${payload.phone}`;

    // Get the currently authenticated user
    var user = firebase.auth().currentUser;

    // Create a PhoneAuthProvider instance
    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    // Initiate phone number verification and get the verification ID
    phoneAuthProvider
      .verifyPhoneNumber(phoneNumberWithCountryCode, appVerifier)
      .then(function (verificationId) {
        console.log("Verification Id", verificationId);

        const verificationCode = window.prompt(
          "Please enter the verification code sent to your mobile phone:"
        );

        console.log("VerificationCode", verificationCode);

        var credential = firebase.auth.PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        // Save the verification ID somewhere for later use
        // You can store it in a database or in a variable, for example
        console.log("Verification ID Credential", verificationId);
        return credential;
      })
      .then((credential) => {
        return user.updatePhoneNumber(credential);
      })
      .then(function () {
        // Phone number updated successfully
        console.log("Phone number update for current user successfully.");
        commit("setUpdateProfilePhoneNumberStatus", "success");
        dispatch("editUserProfile", payload);
      })
      .catch(function (error) {
        // An error occurred while updating the phone number
        console.log("Error in updating current user phone number", error);
        commit("setUpdateProfilePhoneNumberStatus", "failure");
        commit("setUpdateProfilePhoneNumberError", error);
      })
      .catch(function (error) {
        // Handle errors here
        console.log(
          "Error 2 in updating current user phone number 2222 - ",
          error
        );
        commit("setUpdateProfilePhoneNumberStatus", "failure");
        commit("setUpdateProfilePhoneNumberError", error);
      });
  },
  updateUserPhoneNumber({ dispatch, commit, state, getters }, payload) {
    commit("setUpdateUserPhoneNumberStatus", "loading");
    console.log("updateUserPhoneNumber", payload);
    updateUserPhoneNumberInAuth(payload)
      .then((response) => {
        console.log("Response", response);
        let phoneNumberTenDigit = payload.phone
          ? payload.phone.slice(3)
          : payload.phone;
        db.collection("students").doc(payload.uid).update({
          phone: phoneNumberTenDigit,
        });
      })
      .then(() => {
        commit("setUpdateUserPhoneNumberStatus", "success");
        if (getters.isUserGod) {
          dispatch("getStudents", { isCourseSelected: false });
        } else if (getters.isUserAdmin || getters.isUserOffice) {
          dispatch("getStudents", {
            isCourseSelected: false,
            wing: state.user.wing,
          });
        }
      })
      .catch((error) => {
        commit("setUpdateUserPhoneNumberStatus", "failure");
        commit("setUpdateUserPhoneNumberError", error.message);
      });
  },
  deleteUser({ dispatch, getters, state }, payload) {
    console.log("deleteUser", payload);

    db.collection("students")
      .doc(payload.key)
      .delete()
      .then(() => {
        console.log("Delete User Successfull");
        deleteUserFromAuth({ uid: payload.key })
          .then(() => {
            console.log("Deleted user from Auth DB");
          })
          .catch((error) => {
            console.log("Error deleting user from auth db:", error);
          });
        if (getters.isUserGod) {
          dispatch("getStudents", { isCourseSelected: false });
        } else if (getters.isUserAdmin || getters.isUserOffice) {
          dispatch("getStudents", {
            isCourseSelected: false,
            wing: state.user.wing,
          });
        }
      });
  },
  getTests({ commit, state }, payload) {
    console.log("getTests initial", payload);
    commit("setTestsStatus", "loading");
    if (payload) {
      if (payload.course) {
        db.collection("tests")
          .where("course.id", "==", payload.course.id)
          .get()
          .then((querySnapshot) => {
            let tests = [];
            querySnapshot.forEach((doc) => {
              tests.push(doc.data());
            });
            console.log("getTests", tests);
            commit("setTests", tests);
            commit("setTestsStatus", "success");
          })
          .catch((error) => {
            commit("setTestsStatus", "failure");
            commit("setTestsError", error.message);
          });
      } else if (payload.wing) {
        db.collection("tests")
          .where("course.wing", "==", payload.wing)
          .get()
          .then((querySnapshot) => {
            let tests = [];
            querySnapshot.forEach((doc) => {
              tests.push(doc.data());
            });
            console.log("getTests", tests);
            commit("setTests", tests);
            commit("setTestsStatus", "success");
          })
          .catch((error) => {
            commit("setTestsStatus", "failure");
            commit("setTestsError", error.message);
          });
      } else {
        db.collection("tests")
          .where("course.id", "==", payload.course.id)
          .get()
          .then((querySnapshot) => {
            let tests = [];
            querySnapshot.forEach((doc) => {
              tests.push(doc.data());
            });
            console.log("getTests", tests);
            commit("setTests", tests);
            commit("setTestsStatus", "success");
          })
          .catch((error) => {
            commit("setTestsStatus", "failure");
            commit("setTestsError", error.message);
          });
      }
    } else {
      db.collection("tests")
        .get()
        .then((querySnapshot) => {
          let tests = [];
          querySnapshot.forEach((doc) => {
            tests.push(doc.data());
          });
          console.log("getTests", tests);
          commit("setTests", tests);
          commit("setTestsStatus", "success");
        })
        .catch((error) => {
          commit("setTestsStatus", "failure");
          commit("setTestsError", error.message);
        });
    }
  },
  getCourses({ commit }, payload) {
    console.log("getCourses", payload);
    commit("setCoursesStatus", "loading");
    if (payload && payload.wing) {
      db.collection("courses")
        .where("wing", "==", payload.wing)
        .get()
        .then((querySnapshot) => {
          let courses = [];
          querySnapshot.forEach((doc) => {
            courses.push(doc.data());
          });
          commit("setCourses", courses);
          commit("setCoursesStatus", "success");
        })
        .catch((error) => {
          commit("setCoursesStatus", "failure");
          commit("setCoursesError", error.message);
        });
    } else {
      db.collection("courses")
        .get()
        .then((querySnapshot) => {
          let courses = [];
          querySnapshot.forEach((doc) => {
            courses.push(doc.data());
          });
          commit("setCourses", courses);
          commit("setCoursesStatus", "success");
        })
        .catch((error) => {
          commit("setCoursesStatus", "failure");
          commit("setCoursesError", error.message);
        });
    }
  },
  async checkIfTestExists({ commit, state }, payload) {
    const testToBeAdded = db.collection("tests").doc(payload.id);
    const test = await testToBeAdded.get();
    if (!test.exists) {
      commit("setTestExist", false);
    } else {
      console.log("Document data:", test.data());
      commit("setTestExist", true);
      commit("setAddNewTestStatus", "failure");
      commit(
        "setAddNewTestError",
        "This test already exists. Please change the title Or delete the existing test with this title."
      );
    }
  },
  addNewTest({ dispatch, commit, state }, payload) {
    commit("setAddNewTestStatus", "loading");
    console.log("addNewTest", payload);
    db.collection("tests")
      .doc(payload.id)
      .set(payload)
      .then(() => {
        commit("setAddNewTestStatus", "success");
        commit("setAddNewTestError", null);
        db.collection("students")
          .where("course.id", "==", payload.course.id)
          .where("role", "==", "user")
          .get()
          .then((querySnapshot) => {
            let students = [];
            querySnapshot.forEach((doc) => {
              students.push(doc.data());
            });
            return students;
          })
          .then((students) => {
            console.log("students", students);
            for (let student of students) {
              let testDetails = { ...payload };
              testDetails.obtained_marks = 0;
              if (testDetails.subtests_available) {
                for (let i = 0; i < testDetails.subtests.length; i++) {
                  testDetails.subtests[i].obtained_marks = 0;
                }
              }
              console.log(student);
              console.log(student.tests);
              let testFoundIndex = student.tests.findIndex(
                (test) => payload.id === test.id
              );
              if (testFoundIndex > -1) {
                student.tests[testFoundIndex] = { ...testDetails };
              } else {
                student.tests.push(testDetails);
              }
              dispatch("editUser", student);
            }
            dispatch("getTests", { course: payload.course });
          });
      })
      .catch((error) => {
        commit("setAddNewTestError", error);
        commit("setAddNewTestStatus", "failure");
      });
  },
  resetAddTestStates({ commit, state }) {
    commit("setAddNewTestStatus", "");
    commit("setAddNewTestError", null);
  },
  deleteTest({ dispatch, commit, state }, payload) {
    console.log("deleteTest", payload);
    db.collection("tests")
      .doc(payload.id)
      .delete()
      .then(() => {
        console.log("Delete Test Successfull", payload);
        db.collection("students")
          .where("course.id", "==", payload.course.id)
          .where("role", "==", "user")
          .get()
          .then((querySnapshot) => {
            let students = [];
            querySnapshot.forEach((doc) => {
              students.push(doc.data());
            });
            return students;
          })
          .then((students) => {
            console.log("students", students);
            for (let student of students) {
              console.log("for every", student);
              let studentDetails = { ...student };
              let studentTestDetails = [...studentDetails.tests];
              let deletedTest = studentTestDetails.findIndex(
                (test) => test.id == payload.id
              );
              console.log("for every deletedTest index", deletedTest);
              if (deletedTest > -1) {
                studentTestDetails.splice(deletedTest, 1);
              }
              studentDetails.tests = [...studentTestDetails];
              dispatch("editUser", studentDetails);
            }
            dispatch("getTests", { course: payload.course });
          })
          .catch((error) => {
            commit("setStudentsStatus", "failure");
            commit("setStudentsError", error.message);
          });
      });
  },
  editTest({ dispatch, commit }, payload) {
    commit("setUpdateTestStatus", "loading");
    console.log("editTest", payload);
    db.collection("tests")
      .doc(payload.id)
      .set(payload)
      .then(() => {
        commit("setUpdateTestStatus", "success");
        commit("setUpdateTestError", null);

        db.collection("students")
          .where("course.id", "==", payload.course.id)
          .where("role", "==", "user")
          .get()
          .then((querySnapshot) => {
            let students = [];
            querySnapshot.forEach((doc) => {
              students.push(doc.data());
            });
            return students;
          })
          .then((students) => {
            console.log("students", students);
            for (let student of students) {
              let testDetails = { ...payload };

              console.log(student);
              console.log(student.tests);
              let testFoundIndex = student.tests.findIndex(
                (test) => payload.id === test.id
              );
              console.log("Edit Test", testFoundIndex);
              if (testFoundIndex > -1) {
                let editedTestFinal = {
                  ...testDetails,
                  obtained_marks: student.tests[testFoundIndex].obtained_marks,
                };
                if (editedTestFinal.subtests_available) {
                  for (let i = 0; i < editedTestFinal.subtests.length; i++) {
                    let subtestFoundIndex = student.tests[
                      testFoundIndex
                    ].subtests.findIndex(
                      (subtest) => payload.id === subtest.id
                    );
                    if (subtestFoundIndex > -1) {
                      editedTestFinal.subtests[i].obtained_marks =
                        student.tests[testFoundIndex].subtests[
                          subtestFoundIndex
                        ].obtained_marks;
                    } else {
                      editedTestFinal.subtests[i].obtained_marks = 0;
                    }
                  }
                }
                student.tests[testFoundIndex] = {
                  ...editedTestFinal,
                };
                console.log(
                  "EditTest Student final details",
                  editedTestFinal,
                  student,
                  student.tests[testFoundIndex]
                );
              } else {
                student.tests.push(testDetails);
              }
              dispatch("editUser", student);
            }
            dispatch("getTests", { course: payload.course });
          });
      })
      .catch((error) => {
        commit("setUpdateTestStatus", "failure");
        commit("setUpdateTestError", error.message);
      });
  },
  addCourse({ dispatch, commit, getters, state }, payload) {
    commit("setAddNewCourseStatus", "loading");
    console.log("addCourse", payload);
    db.collection("courses")
      .doc(payload.id)
      .set(payload)
      .then(() => {
        commit("setAddNewCourseStatus", "success");
        if (getters.isUserGod) {
          dispatch("getCourses");
        } else if (getters.isUserAdmin || getters.isUserOffice) {
          dispatch("getCourses", { wing: state.user.wing });
        }
      })
      .catch((error) => {
        commit("setAddNewCourseStatus", "failure");
        commit("setAddNewCourseError", error.message);
      });
  },
  editCourse({ dispatch, commit }, payload) {
    commit("setEditCourseStatus", "loading");
    console.log("editCourse", payload);
    db.collection("courses")
      .doc(payload.id)
      .set(payload)
      .then(() => {
        commit("setEditCourseStatus", "success");
        db.collection("students")
          .where("course.id", "==", payload.id)
          .get()
          .then((querySnapshot) => {
            let students = [];
            querySnapshot.forEach((doc) => {
              students.push(doc.data());
            });
            console.log("All users of this course", students);
            return students;
          })
          .then((students) => {
            console.log("Course edit students", students);
            for (let student of students) {
              let courseDetails = { ...payload };
              console.log(student);
              student.course = { ...courseDetails };
              console.log("Edited user with course details", student);
              dispatch("editUser", student);
            }
          });
        db.collection("tests")
          .where("course.id", "==", payload.id)
          .get()
          .then((querySnapshot) => {
            let tests = [];
            querySnapshot.forEach((doc) => {
              tests.push(doc.data());
            });
            console.log("All tests of this course", tests);
            return tests;
          })
          .then((tests) => {
            console.log("Course edit tests", tests);
            for (let test of tests) {
              let courseDetails = { ...payload };
              console.log(test);
              test.course = { ...courseDetails };
              console.log("Edited test with course details", test);
              db.collection("tests")
                .doc(test.id)
                .set(test)
                .then(() => {
                  commit("setUpdateTestStatus", "success");
                  commit("setUpdateTestError", null);
                })
                .catch((error) => {
                  commit("setUpdateTestStatus", "failure");
                  commit("setUpdateTestError", error.message);
                });
            }
          });
      })
      .catch((error) => {
        commit("setEditCourseStatus", "failure");
        commit("setEditCourseError", error.message);
      });
  },
  deleteCourse({ dispatch, commit, state, getters }, payload) {
    console.log("deleteCourse", payload);
    db.collection("courses")
      .doc(payload.id)
      .delete()
      .then(() => {
        console.log("Delete Course Successfull", payload);
        if (getters.isUserGod) {
          dispatch("getCourses");
        } else if (getters.isUserAdmin || getters.isUserOffice) {
          dispatch("getCourses", { wing: state.user.wing });
        }
        db.collection("students")
          .where("course.id", "==", payload.id)
          .get()
          .then((querySnapshot) => {
            let students = [];
            querySnapshot.forEach((doc) => {
              students.push(doc.data());
            });
            console.log("All users of this course", students);
            return students;
          })
          .then((students) => {
            console.log("Course delete students", students);
            for (let student of students) {
              console.log("Deleted user with course details", student);
              dispatch("deleteUser", student);
            }
          });
        db.collection("tests")
          .where("course.id", "==", payload.id)
          .get()
          .then((querySnapshot) => {
            let tests = [];
            querySnapshot.forEach((doc) => {
              tests.push(doc.data());
            });
            console.log("All tests of this course", tests);
            return tests;
          })
          .then((tests) => {
            console.log("Course delete tests", tests);
            for (let test of tests) {
              console.log("Deleted test with course details", test);
              db.collection("tests")
                .doc(test.id)
                .delete()
                .then(() => {
                  console.log("Delete Test Successfull", payload);
                });
            }
          });
      });
  },
  updateCurrentCourse({ commit }, payload) {
    console.log("updateCurrentCourse payload", payload);
    commit("setCurrentCourse", payload);
  },
  resetStatesOnPasswordReset({ commit }) {
    commit("setError", null);
    commit("setStatus", "");
  },
  logActivityToHistory({ dispatch, commit, state }, payload) {
    console.log("logActivityToHistory", payload);
    db.collection("history")
      .doc()
      .set(payload)
      .then(() => {
        console.log("Logged History");
        dispatch("getHistory");
      })
      .catch((error) => {
        console.log("Error in logging history", error);
      });
  },
  getHistory({ commit, state, getters }, payload) {
    console.log("getHistory", payload, state.user.wing);
    commit("setHistoryStatus", "loading");
    if (payload && payload.test) {
      db.collection("history")
        .where("test.id", "==", payload.test.id)
        .get()
        .then((querySnapshot) => {
          let history = [];
          querySnapshot.forEach((doc) => {
            // console.log("doc", doc, doc.data());
            history.push({ ...doc.data(), id: doc.id });
          });
          // console.log("History", history);

          commit("setHistory", history);
          commit("setHistoryStatus", "success");
        })
        .catch((error) => {
          commit("setHistoryStatus", "failure");
          commit("setHistoryError", error.message);
        });
    } else if (payload && payload.course) {
      db.collection("history")
        .where("student.course.id", "==", payload.course.id)
        .get()
        .then((querySnapshot) => {
          let history = [];
          querySnapshot.forEach((doc) => {
            // console.log("doc", doc, doc.data());
            history.push({ ...doc.data(), id: doc.id });
          });
          // console.log("History", history);

          commit("setHistory", history);
          commit("setHistoryStatus", "success");
        })
        .catch((error) => {
          commit("setHistoryStatus", "failure");
          commit("setHistoryError", error.message);
        });
    } else if (getters.isUserGod) {
      db.collection("history")
        .get()
        .then((querySnapshot) => {
          let history = [];
          querySnapshot.forEach((doc) => {
            // console.log("doc", doc, doc.data());
            history.push({ ...doc.data(), id: doc.id });
          });
          // console.log("History", history);
          commit("setHistory", history);
          commit("setHistoryStatus", "success");
        })
        .catch((error) => {
          commit("setHistoryStatus", "failure");
          commit("setHistoryError", error.message);
        });
    } else {
      db.collection("history")
        .where("student.course.wing", "==", state.user.wing)
        .get()
        .then((querySnapshot) => {
          let history = [];
          querySnapshot.forEach((doc) => {
            // console.log("doc", doc, doc.data());
            history.push({ ...doc.data(), id: doc.id });
          });
          // console.log("History", history);
          commit("setHistory", history);
          commit("setHistoryStatus", "success");
        })
        .catch((error) => {
          commit("setHistoryStatus", "failure");
          commit("setHistoryError", error.message);
        });
    }
  },
  setDeveloperProfilePhoto({ commit }) {
    getDownloadURL(ref(storage, "images/profile/developer.jpeg"))
      .then((url) => {
        // `url` is the download URL for 'images/profile/developer.jpeg'
        console.log("setDeveloperProfilePhoto url", url);
        commit("setDeveloperProfileUrl", url);
      })
      .catch((error) => {
        // Handle any errors
        console.log("Error in setDeveloperProfilePhoto", error);
      });
  },
};
const getters = {
  isUserGod(state) {
    return state.user && state.user.role == "god";
  },
  isUserAdmin(state) {
    return (
      state.user && (state.user.role == "god" || state.user.role == "admin")
    );
  },
  isUserOffice(state) {
    return (
      state.user &&
      (state.user.role == "god" ||
        state.user.role == "admin" ||
        state.user.role == "office")
    );
  },
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
