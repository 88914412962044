<template>
  <div>
    <div
      class="bg-brand text-center text-lg-start px-5 py-3 d-flex flex-column"
    >
      <div
        class="d-flex justify-content-between align-items-start border-bottom pb-2 flex-wrap"
      >
        <div
          class="text-center py-0 d-flex flex-column justify-content-start align-items-start"
        >
          <p class="font-lg font-w500 mb-1">Developed by</p>
          <div class="d-flex justify-content-between align-items-start">
            <b-avatar
              class="bg-white text-brand"
              size="48px"
              text="GL"
              :src="developerProfilePhoto"
            ></b-avatar>
            <div
              class="d-flex justify-content-start align-items-start flex-column mx-3"
            >
              <!-- <p class="font-md mb-0 text-muted">Developed and maintained by</p> -->

              <p class="mb-0 font-lg">Gaurav Lamoriya</p>
              <p class="mb-0 font-md">Assistant Commandant</p>
              <p class="mb-0 font-md">145 Bn</p>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-start align-items-start py-2"
        >
          <p class="font-lg font-w500 mb-1">Contact us</p>
          <p
            class="mb-0 d-flex justify-content-start align-items-center font-md"
          >
            <i class="fa-solid fa-envelope font-sm"></i>
            <span class="mx-2">gaurav.lamoriya91@gmail.com</span>
          </p>
          <p
            v-if="user"
            class="mb-0 d-flex justify-content-start align-items-center font-md"
          >
            <i class="fa-solid fa-phone font-sm"></i>
            <span class="mx-2">+91 8101334284</span>
          </p>
        </div>
        <div
          class="d-flex flex-column justify-content-start align-items-start py-2"
        >
          <p class="font-lg font-w500 mb-1">Information</p>
          <p class="mb-1 font-md" @click="nav('about-us')">About CSWT</p>
          <p class="mb-1 font-md" @click="nav('history')">History</p>
          <p class="mb-1 font-md" @click="nav('activities')">Activities</p>
        </div>
        <div
          class="d-flex flex-column justify-content-start align-items-start py-2"
        >
          <p class="font-lg font-w500 mb-1">Links</p>
          <a href="https://bsf.gov.in/" class="mb-1 font-md">BSF</a>
          <a
            href="https://bsf.gov.in/@cswtindore/about-us.html"
            class="mb-1 font-md"
            >CSWT</a
          >
        </div>
        <div
          class="d-flex flex-column justify-content-start align-items-start py-2"
        >
          <p class="font-lg font-w500 mb-1">Social Media</p>
          <div class="d-flex justify-content-between flex-wrap">
            <a
              href="https://www.facebook.com/OfficialPageBSF/"
              class="mx-1 font-xl"
              ><i class="fa-brands fa-facebook"></i
            ></a>
            <a href="https://www.instagram.com/bsf_india/" class="mx-1 font-xl"
              ><i class="fa-brands fa-instagram"></i
            ></a>
            <a
              href="https://www.youtube.com/channel/UCW8j_nGsu63WHMhhCw3e9eA"
              class="mx-1 font-xl"
              ><i class="fa-brands fa-youtube"></i
            ></a>
            <a href="https://twitter.com/bsf_india" class="mx-1 font-xl"
              ><i class="fa-brands fa-twitter"></i
            ></a>
          </div>
        </div>
      </div>
      <p
        class="mt-2 d-flex justify-content-center align-items-center flex-wrap font-md"
      >
        <span
          >2023
          <i class="fa-regular fa-copyright font-sm"></i>
          cswttraining.in.
        </span>
        <span class="mx-1">All rights reserved.</span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { BAvatar } from "bootstrap-vue";
export default {
  name: "FooterComponent",
  components: {
    BAvatar,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      developerProfilePhoto: (state) => state.developerProfilePhoto,
    }),
  },
  methods: {
    nav(linkId) {
      console.log(
        "document.getElementById(linkId)",
        document.getElementById(linkId),
        linkId
      );
      const position = document.getElementById(linkId).offsetTop;

      // smooth scroll
      window.scrollTo({ top: position, behavior: "smooth" });
    },
  },
};
</script>