<template>
  <b-modal
    ref="addCourseModal"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
  >
    <div id="add-course-container">
      <b-form
        class="container justify-content-center w-auto"
        @submit.prevent="addNewCourse"
      >
        <b-form-group
          v-if="isUserGod"
          id="select-wing-for-add-course-id"
          class="pb-3 col-5"
          label="Wing"
          label-for="select-wing-for-add-course"
        >
          <b-form-select
            id="select-wing-for-add-course"
            v-model="selectedWing"
            :options="wings"
            label-field="title"
            text-field="title"
            value-field="title"
            size="sm"
            class="form-control form-control-sm w-25 mb-4 col"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Select Wing</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="course-title-id"
          class="pb-3 col-5"
          label="Full Title"
          label-for="full-title"
          :description="
            courseIdToShow ? `Course Id will be ` + courseIdToShow : ''
          "
        >
          <b-form-input
            class="form-control form-control-sm"
            id="course-title"
            v-model="course.title"
            type="text"
            placeholder="Enter course title"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn btn-sm px-3 bg-brand">
          Add Course
          <i
            v-if="addCourseStatus == 'loading'"
            class="fa-solid fa-spinner fa-spin pl-3"
          ></i
        ></b-button>
        <b-button class="btn btn-sm px-3 mx-2" type="reset">Reset</b-button>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  BTable,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
export default {
  name: "AddCourse",
  components: {
    BTable,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BModal,
  },
  data() {
    return {
      course: {
        title: "",
        id: "",
        wing: null,
      },
      selectedWing: null,
      wings: ["PW", "WT", "AT"],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      addCourseStatus: (state) => state.addCourseStatus,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),

    courseIdToShow() {
      if (this.course.title) {
        return this.course.title.toLowerCase().split(" ").join("-");
      }
      return "";
    },
  },
  methods: {
    ...mapActions(["addCourse"]),
    addNewCourse() {
      console.log("addNewCourse", {
        ...this.course,
        id: this.courseIdToShow,
        wing: this.selectedWing,
      });
      if (this.isUserGod) {
        this.addCourse({
          ...this.course,
          id: this.courseIdToShow,
          wing: this.selectedWing,
        });
        this.$emit("onOk");
      } else if (this.isUserAdmin || this.isUserOffice) {
        this.addCourse({
          ...this.course,
          id: this.courseIdToShow,
          wing: this.user.wing,
        });
        this.$emit("onOk");
      }
    },
  },
};
</script>
<style scoped>
.input-label {
  font-size: 14px;
}
</style>
<style>
.form-group label {
  font-size: 14px;
}
</style>