<template>
  <b-modal
    :ref="`edit-modal-${course.id}`"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
  >
    <div id="edit-course-container">
      <b-form
        class="container justify-content-center w-auto"
        @submit.prevent="onEditCourse"
      >
        <!-- <label for="select-wing-for-edit-course" class="input-label">Wing</label
        ><b-form-select
          id="select-wing-for-edit-course"
          v-model="course.wing"
          :options="wings"
          label-field="title"
          text-field="title"
          value-field="title"
          size="sm"
          class="form-control form-control-sm w-25 mb-4 col"
          required
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Wing</b-form-select-option
            >
          </template>
        </b-form-select> -->
        <b-form-group
          id="course-title-id"
          class="pb-3 col-5"
          label="Full Title"
          label-for="full-title"
        >
          <b-form-input
            class="form-control form-control-sm"
            id="course-title"
            v-model="course.title"
            type="text"
            placeholder="Enter course title"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn btn-sm px-3 bg-brand">
          Edit Course
          <i
            v-if="editCourseStatus == 'loading'"
            class="fa-solid fa-spinner fa-spin pl-3"
          ></i
        ></b-button>
        <b-button class="btn btn-sm px-3 mx-2" type="reset">Reset</b-button>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
} from "bootstrap-vue";
export default {
  name: "EditCourse",
  components: {
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
  },
  props: {
    course: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedWing: null,
      wings: ["PW", "WT", "AT"],
    };
  },
  computed: {
    ...mapState({
      editCourseStatus: (state) => state.editCourseStatus,
    }),
  },
  methods: {
    ...mapActions(["editCourse"]),
    onEditCourse() {
      this.editCourse({
        ...this.course,
      });
      this.$emit("onOk");
    },
  },
};
</script>
<style scoped>
.input-label {
  font-size: 14px;
}
</style>
<style>
.form-group label {
  font-size: 14px;
}
</style>