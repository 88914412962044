<template>
  <div class="container mt-5">
    <div
      v-if="phoneError"
      class="login-error border border-danger rounded p-2 mb-3 text-danger"
    >
      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
      {{ phoneError }}
    </div>
    <div
      v-if="updatePhoneNumberInAuthError"
      class="login-error border border-danger text-danger rounded p-2 mb-3"
    >
      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
      {{ updatePhoneNumberInAuthError }}
    </div>
    <b-form
      class="container justify-content-center mx-4 mb-5 w-auto"
      @reset="onReset"
    >
      <b-form-group
        id="login-phone-number-id"
        class="pb-3"
        label="Phone Number"
        label-for="login-phone-number"
        description="Enter your 10 digit phone number associated with the account."
      >
        <b-form-input
          id="login-phone-number"
          v-model="phone"
          type="text"
          placeholder="Enter phone number"
          required
        ></b-form-input>
      </b-form-group>

      <b-button
        type="submit"
        class="btn btn-sm bg-brand mb-2"
        @click.prevent="login"
        >Update Phone Number</b-button
      >
      <b-button
        class="btn btn-sm btn-outline-brand mx-3 mb-2"
        @click.prevent="skip"
        >Skip now</b-button
      >
      <div id="recaptcha-container"></div>
    </b-form>
  </div>
</template>
<script>
import { BFormInput, BFormGroup, BForm, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "LinkPhonenumberPage",
  components: { BFormInput, BFormGroup, BForm, BButton },
  data() {
    return {
      phone: "",
      phoneError: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      updateUserStatus: (state) => state.updateUserStatus,
      updatePhoneNumberInAuthStatus: (state) =>
        state.updatePhoneNumberInAuthStatus,
      updatePhoneNumberInAuthError: (state) =>
        state.updatePhoneNumberInAuthError,
    }),
  },
  methods: {
    ...mapActions(["updatePhoneNumberInAuth"]),
    async login() {
      if (this.phone.length == 10) {
        const user = {
          phone: `+91${this.phone}`,
          key: this.user.key,
        };
        await this.updatePhoneNumberInAuth(user);
      } else if (this.phone.length > 0) {
        this.phoneError = `Phone number is invalid. Add a valid 10 digit phone number.`;
      } else {
        this.phoneError = `Add a 10 digit phone number now of skip for later.`;
      }
    },
    skip() {
      this.$router.push("/results");
    },
    onReset() {
      this.phone = "";
    },
  },
  watch: {
    updatePhoneNumberInAuthStatus(val) {
      console.log("updatePhoneNumberInAuthStatus watcher", val);
      if (val == "success") {
        this.$router.push("/results");
      }
    },
    updateUserStatus(val) {
      console.log("updateUserStatus watcher", val);
      if (val === "success") {
        this.$router.push("/results");
      }
    },
  },
};
</script>
<style scoped>
.login-error {
  margin-left: 2.2rem !important;
  margin-right: 2.2rem !important;
}
</style>