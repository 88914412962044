<template>
  <div>
    <div>
      <div
        v-if="addNewCourseStatus === 'loading' || coursesStatus === 'loading'"
        class="container d-flex justify-content-center align-items-center flex-column bg-white rounded loading-page mx-4 my-3 border font-xl"
      >
        <i class="fa-solid fa-spinner fa-spin"></i>
        <p class="mt-2">Fetching courses</p>
      </div>
      <div
        v-else-if="
          addNewCourseStatus === 'failure' || coursesStatus === 'failure'
        "
      >
        Failed!!
      </div>
      <div v-else>
        <div
          v-if="!courses.length"
          class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page mx-4 my-3 w-auto border"
        >
          <div class="pb-3 px-4 text-center">
            There are no courses added for this course. Go ahead and add
            courses.
          </div>
          <b-button
            class="btn btn-sm btn-outline-brand px-3"
            @click="openAddCourseModal2"
            >Add Course</b-button
          ><AddCourse
            ref="addCourseModal2"
            id="add-course-modal"
            :title="'Add Course Details'"
            @onOk="hideAddCourseModal2"
            @onCancel="hideAddCourseModal2"
          />
        </div>
        <div v-else>
          <div class="d-flex justify-content-between align-items-center">
            <b-badge
              class="mr-2 px-2 py-1 badge-filters border-brand text-brand"
              pill
              >{{ courses.length }}
              {{ courses.length > 1 ? "courses" : "course" }} added</b-badge
            >
            <div class="d-flex justify-content-end align-items-center">
              <button
                class="btn btn-sm btn-outline-brand"
                @click="openAddCourseModal1"
              >
                Add Course
              </button>
              <AddCourse
                ref="addCourseModal1"
                id="add-course-modal"
                :title="'Add Course Details'"
                @onOk="hideAddCourseModal1"
                @onCancel="hideAddCourseModal1"
              />
            </div>
          </div>
          <div class="my-3 border rounded bg-white table-container">
            <b-table
              hover
              primary-key="id"
              :items="courses"
              :fields="fields"
              class="w-100 mb-0"
            >
              <template #cell(actions)="row">
                <button
                  class="btn btn-sm text-primary action-buttons"
                  @click="openEditCourseModal(row.item)"
                >
                  Edit
                </button>
                <button
                  v-if="isUserGod"
                  class="btn btn-sm text-danger action-buttons"
                  @click="openDeleteCourseConfirmation(row.item)"
                >
                  Delete
                </button>
                <ConfirmationModal
                  v-if="isUserGod"
                  :ref="`delete-course-${row.item.id}-modal`"
                  :id="row.item.id"
                  :title="'Delete Course'"
                  :content="`Are you sure you want to delete this course - ${row.item.title}?`"
                  :cancelButtonText="'Cancel'"
                  :okButtonText="'Delete'"
                  @onOk="deleteCourseDetails(row.item)"
                  @onCancel="hideDeleteConfirmation(row.item)"
                />
                <EditCourse
                  :ref="`edit-course-${row.item.id}-modal`"
                  :id="row.item.id"
                  :course="row.item"
                  :title="'Edit Course Details'"
                  @onOk="hideEditCourseModal(row.item)"
                  @onCancel="hideEditCourseModal(row.item)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  BTable,
  BButton,
  BCollapse,
  BCard,
  BBadge,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  VBToggle,
} from "bootstrap-vue";
import AddCourse from "@/components/modal/AddCourse.vue";
import EditCourse from "@/components/modal/EditCourse.vue";
import ConfirmationModal from "@/components/modal/ConfirmationModal.vue";

export default {
  name: "CoursesPage",
  components: {
    BTable,
    BButton,
    BCollapse,
    BCard,
    BBadge,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    AddCourse,
    EditCourse,
    ConfirmationModal,
  },
  directives: { "b-toggle": VBToggle },
  beforeMount() {
    if (this.isUserGod) {
      this.getCourses();
    } else if (this.isUserAdmin || this.isUserOffice) {
      this.getCourses({ wing: this.user.wing });
    }
  },
  data() {
    return {
      fields: [
        { key: "title", label: "Title", sortable: true, sortDirection: "asc" },
        {
          key: "wing",
          label: "Wing",
          sortable: true,
          class: "text-center",
        },
        { key: "actions", label: "Actions", class: "text-center" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      addNewCourseStatus: (state) => state.addNewCourseStatus,
      coursesStatus: (state) => state.coursesStatus,
      courses: (state) => state.courses,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),
  },
  methods: {
    ...mapActions(["getCourses", "deleteCourse"]),
    hideAddCourseModal1() {
      this.$refs.addCourseModal1.$refs.addCourseModal.hide();
    },
    openAddCourseModal1() {
      this.$refs.addCourseModal1.$refs.addCourseModal.show();
    },
    hideAddCourseModal2() {
      this.$refs.addCourseModal2.$refs.addCourseModal.hide();
    },
    openAddCourseModal2() {
      this.$refs.addCourseModal2.$refs.addCourseModal.show();
    },
    hideEditCourseModal(course) {
      this.$refs[`edit-course-${course.id}-modal`].$refs[
        `edit-modal-${course.id}`
      ].hide();
    },
    openEditCourseModal(course) {
      this.$refs[`edit-course-${course.id}-modal`].$refs[
        `edit-modal-${course.id}`
      ].show();
    },
    deleteCourseDetails(course) {
      this.deleteCourse(course);
    },
    hideDeleteConfirmation(course) {
      this.$refs[`delete-course-${course.id}-modal`].$refs[
        `confirmation-modal-${course.id}`
      ].hide();
    },
    openDeleteCourseConfirmation(course) {
      this.$refs[`delete-course-${course.id}-modal`].$refs[
        `confirmation-modal-${course.id}`
      ].show();
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow-x: auto;
}
.table {
  overflow: auto;
  width: auto;
  padding: 0 !important;
}
</style>