<template>
  <div>
    <div
      class="d-flex align-items-center w-auto"
      :class="
        selectedCourse ? 'justify-content-between' : 'justify-content-start'
      "
    >
      <b-form-group
        id="courses-list-id"
        label="Select Course"
        label-for="courses-list"
        class="pb-3 w-auto"
      >
        <b-form-select
          id="courses-list"
          v-model="selectedCourse"
          :options="courses"
          label-field="title"
          text-field="title"
          value-field="id"
          size="sm"
          class="form-control form-control-sm mr-4"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Course</b-form-select-option
            >
          </template>
        </b-form-select></b-form-group
      >
      <b-button
        v-if="selectedCourse"
        class="btn btn-sm btn-outline-brand px-3"
        @click="openAddTestModal1"
        >Add Test</b-button
      >
      <AddTests
        v-if="selectedCourse"
        ref="addTestModal1"
        id="add-test-modal"
        :title="'Add Test Details'"
        :selectedCourse="selectedCourse"
        @onOk="hideAddTestModal1"
        @onCancel="hideAddTestModal1"
      />
    </div>
    <div class="d-flex justify-content-between align-items-end">
      <div class="d-flex justify-content-start align-items-end">
        <b-badge
          v-if="courseDetails"
          class="mr-2 px-2 py-1 badge-filters border-brand text-brand"
          pill
          >{{ this.courseDetails.title }}
          <span @click="selectedCourse = null">
            <i class="fa fa-regular fa-circle-xmark text-danger px-2"></i>
          </span>
        </b-badge>
        <b-badge
          class="px-2 py-1 badge-filters border-brand text-brand"
          :class="courseDetails ? 'mx-2' : ''"
          pill
          >{{ tests.length }}
          {{ tests.length > 1 ? "tests" : "test" }} added</b-badge
        >
      </div>
    </div>

    <div
      v-if="addNewTestStatus === 'loading' || testsStatus === 'loading'"
      class="container d-flex justify-content-center align-items-center flex-column bg-white rounded loading-page my-3 border font-xl"
    >
      <i class="fa-solid fa-spinner fa-spin"></i>
      <p class="mt-2">Fetching tests</p>
    </div>
    <div
      v-else-if="addNewTestStatus === 'failure' || testsStatus === 'failure'"
      class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page my-3 border"
    >
      <div class="pb-3 px-4 text-center">Get Tests request has failed.</div>

      <button
        class="btn btn-sm btn-outline-brand margin-left-2"
        @click="onFetchTests"
      >
        Try Again
      </button>
    </div>
    <div v-else>
      <div
        v-if="!tests.length"
        class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page my-3 w-auto border"
      >
        <div class="pb-3 px-4 text-center">
          There are no tests added for this course. Go ahead and add tests.
        </div>
        <b-button
          class="btn btn-sm btn-outline-brand px-3"
          @click="openAddTestModal2"
          >Add Test</b-button
        >
        <AddTests
          ref="addTestModal2"
          id="add-test-modal"
          :title="'Add Test Details'"
          :selectedCourse="selectedCourse"
          @onOk="hideAddTestModal2"
          @onCancel="hideAddTestModal2"
        />
      </div>

      <div v-else class="my-3 border rounded bg-white table-container">
        <b-table
          hover
          primary-key="id"
          :items="tests"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="w-100 mb-0"
        >
          <template #cell(subtests)="row">
            <button
              v-if="row.item.subtests_available"
              class="btn btn-sm text-primary show-subtests-button"
              v-b-toggle="`collapse-${row.item.id}`"
            >
              Show subtests
              <i class="fa fa-xs fa-angle-down text-primary"></i>
            </button>
            <b-collapse
              v-if="row.item.subtests_available"
              :id="`collapse-${row.item.id}`"
              class="mt-2"
            >
              <SubtestCard :test="row.item" />
            </b-collapse>
          </template>
          <template #cell(actions)="row">
            <button
              class="btn btn-sm text-primary action-buttons"
              @click="openEditTestModal(row.item)"
            >
              Edit
            </button>
            <button
              class="btn btn-sm text-danger action-buttons"
              @click="openDeleteTestConfirmationModal(row.item)"
            >
              Delete
            </button>
            <ConfirmationModal
              :ref="`delete-test-${row.item.id}-modal`"
              :id="row.item.id"
              :title="'Delete Test'"
              :content="'Are you sure you want to delete this test?'"
              :cancelButtonText="'Cancel'"
              :okButtonText="'Delete'"
              @onOk="deleteTestDetails(row.item)"
              @onCancel="hideDeleteConfirmation(row.item)"
            />
            <EditTest
              :ref="`edit-test-${row.item.id}-modal`"
              :id="row.item.id"
              :test="row.item"
              :title="'Edit Test Details'"
              @onOk="hideEditTestModal(row.item)"
              @onCancel="hideEditTestModal(row.item)"
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  BTable,
  BButton,
  BCollapse,
  BCard,
  BBadge,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  VBToggle,
} from "bootstrap-vue";
import AddTests from "@/components/modal/AddTests.vue";
import EditTest from "@/components/modal/EditTest.vue";
import SubtestCard from "@/components/SubtestCard.vue";
import ConfirmationModal from "@/components/modal/ConfirmationModal.vue";

export default {
  name: "TestsListPage",
  components: {
    BTable,
    BButton,
    BCollapse,
    BCard,
    BBadge,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    AddTests,
    EditTest,
    SubtestCard,
    ConfirmationModal,
  },
  directives: { "b-toggle": VBToggle },
  beforeMount() {
    if (this.isUserGod) {
      this.getCourses();
      this.getTests();
    } else if (this.isUserAdmin || this.isUserOffice) {
      this.getCourses({ wing: this.user.wing });
      this.getTests({ wing: this.user.wing });
    }
  },
  data() {
    return {
      sortBy: "course.title",
      sortDesc: false,
      fields: [
        { key: "title", label: "Title", sortable: true, sortDirection: "asc" },
        {
          key: "course.title",
          label: "Course",
          sortable: true,
          class: "text-center",
        },
        {
          key: "total_marks",
          label: "Total",
          sortable: true,
          class: "text-center",
        },
        {
          key: "weightage_marks",
          label: "Weightage",
          sortable: true,
          class: "text-center",
        },
        { key: "subtests", label: "Subtests", class: "text-center" },
        { key: "actions", label: "Actions", class: "text-center" },
      ],
      selectedCourse: null,
      courseDetails: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      tests: (state) => state.tests,
      addNewTestStatus: (state) => state.addNewTestStatus,
      testsStatus: (state) => state.testsStatus,
      courses: (state) => state.courses,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),
  },
  methods: {
    ...mapActions(["getTests", "getCourses", "deleteTest", "editTest"]),
    hideAddTestModal1() {
      this.$refs.addTestModal1.$refs.addTestModal.hide();
    },
    openAddTestModal1() {
      this.$refs.addTestModal1.$refs.addTestModal.show();
    },
    hideAddTestModal2() {
      this.$refs.addTestModal2.$refs.addTestModal.hide();
    },
    openAddTestModal2() {
      this.$refs.addTestModal2.$refs.addTestModal.show();
    },
    hideEditTestModal(test) {
      this.$refs[`edit-test-${test.id}-modal`].$refs[
        `edit-modal-${test.id}`
      ].hide();
    },
    openEditTestModal(test) {
      this.$refs[`edit-test-${test.id}-modal`].$refs[
        `edit-modal-${test.id}`
      ].show();
    },
    deleteTestDetails(test) {
      this.deleteTest(test);
    },
    hideDeleteConfirmation(test) {
      this.$refs[`delete-test-${test.id}-modal`].$refs[
        `confirmation-modal-${test.id}`
      ].hide();
    },
    openDeleteTestConfirmationModal(test) {
      this.$refs[`delete-test-${test.id}-modal`].$refs[
        `confirmation-modal-${test.id}`
      ].show();
    },
    onEditTest(test) {
      this.editTest(test);
    },
    onFetchTests() {
      if (this.isUserGod) {
        this.getTests();
      } else if (this.isUserAdmin || this.isUserOffice) {
        this.getTests({ wing: this.user.wing });
      }
    },
  },
  watch: {
    selectedCourse(val) {
      console.log("selectedCourse watcher", val, this.courses);
      if (val) {
        this.courseDetails = this.courses.find((course) => course.id == val);
        this.getTests({ course: this.courseDetails, wing: this.user.wing });
      } else {
        if (this.isUserGod) {
          this.courseDetails = null;
          this.getTests();
        } else {
          this.courseDetails = null;
          this.getTests({ wing: this.user.wing });
        }
      }
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow-x: auto;
}
.table {
  overflow: auto;
  width: auto;
  padding: 0 !important;
}

.show-subtests-button {
  font-weight: 500;
}
</style>