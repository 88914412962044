<template>
  <b-modal
    ref="addTestModal"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
  >
    <div id="add-tests-container">
      <b-form
        class="container justify-content-center"
        @submit.prevent="addTest"
      >
        <!-- <label for="select-course-for-add-test" class="input-label"
          >Course</label
        ><b-form-select
          id="select-course-for-add-test"
          v-model="selectedCourse"
          :options="courses"
          label-field="title"
          text-field="title"
          value-field="id"
          size="sm"
          class="form-control form-control-sm w-25 mb-4 col"
          required
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Course</b-form-select-option
            >
          </template>
        </b-form-select> -->
        <div
          class="d-flex justify-content-start align-items-center flex-wrap border-bottom mb-4 pb-2"
        >
          <b-form-group
            id="test-title-id"
            class="pb-3 col-5"
            label="Full Title"
            label-for="full-title"
          >
            <b-form-input
              class="form-control form-control-sm"
              id="test-title"
              v-model="test.title"
              type="text"
              placeholder="Enter test title"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="test-total-marks-id"
            class="pb-3 col-5 px-3"
            label="Total Marks"
            label-for="test-total-marks"
          >
            <b-form-input
              class="form-control form-control-sm"
              id="test-total-marks"
              v-model.number="test.total_marks"
              type="number"
              min="0"
              placeholder="Enter Total Marks"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="test-weightage-marks-id"
            class="pb-3 col-5"
            label="Weightage Marks"
            label-for="test-weightage-marks"
          >
            <b-form-input
              class="form-control form-control-sm"
              id="test-weightage-marks"
              v-model.number="test.weightage_marks"
              type="number"
              placeholder="Enter Weightage Marks"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="test-type-id"
            class="pb-3 col-5 px-3"
            label="Test Type"
            label-for="test-type"
          >
            <b-form-select
              id="test-type"
              v-model="test.type"
              :options="testTypes"
              label-field="title"
              text-field="title"
              value-field="id"
              size="sm"
              class="form-control form-control-sm"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select Test Type</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div
          class="d-flex align-items-start"
          :class="
            test.subtests_available
              ? 'justify-content-between'
              : 'justify-content-start'
          "
        >
          <div v-if="test.subtests_available">
            Subtests ({{ test.subtests.length }})
          </div>
          <div
            class="d-flex align-items-center"
            :class="
              test.subtests_available
                ? 'justify-content-end'
                : 'justify-content-start'
            "
          >
            <b-form-group
              id="subtests-available-id"
              class="cursor-pointer pb-3 d-flex"
              :class="test.subtests_available ? 'mx-3' : ''"
              :label="
                test.subtests_available ? 'Remove subtests' : 'Has Subsets?'
              "
              label-for="subtests-available"
            >
              <b-form-checkbox
                id="subtests-available"
                v-model="test.subtests_available"
                name="subtests_available-button"
                class="px-2"
                switch
              >
              </b-form-checkbox>
            </b-form-group>
            <b-button
              v-if="test.subtests_available"
              class="btn btn-sm btn-outline-brand px-3 mb-3"
              @click="addMoreSubtest()"
              >Add subtest</b-button
            >
          </div>
        </div>

        <div v-if="test.subtests_available">
          <div
            v-for="(subtest, index) in test.subtests"
            :key="index"
            class="border rounded px-4 py-3 mb-3 bg-white"
          >
            <div
              class="d-flex justify-content-start align-items-center flex-wrap"
            >
              <b-form-group
                id="subtest-title-id"
                class="pb-3 col-5"
                label="Full SubTest Title"
                label-for="full-title"
              >
                <b-form-input
                  class="form-control form-control-sm"
                  id="subtest-title"
                  v-model="subtest.title"
                  type="text"
                  placeholder="Enter test title"
                  @change="subtestIdToShow(subtest, index)"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="subtest-total-marks-id"
                class="pb-3 col-5 px-3"
                label="Total Marks"
                label-for="subtest-total-marks"
              >
                <b-form-input
                  class="form-control form-control-sm"
                  id="subtest-total-marks"
                  v-model.number="subtest.total_marks"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder="Enter Total Marks"
                  @change="subtest.weightage_marks = subtest.total_marks"
                ></b-form-input>
              </b-form-group>

              <b-button
                class="btn btn-sm bg-transparent text-danger border-danger px-3"
                @click="discardCurrentSubtest(index)"
                ><i class="fa fa-solid fa-trash px-2"></i>Discard</b-button
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <b-button
            type="submit"
            class="bg-brand mb-2"
            :disabled="!marksTotalBalanced"
            >Submit</b-button
          >
          <p v-if="!marksTotalBalanced" class="mb-0 text-danger font-sm">
            {{ marksTotalError }}
          </p>
        </div>

        <p v-if="this.addNewTestError" class="mb-0 text-danger font-sm">
          {{ this.addNewTestError }}
        </p>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  BTable,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
export default {
  name: "AddTests",
  components: {
    BTable,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BModal,
  },
  beforeMount() {
    if (this.isUserGod) {
      this.getCourses();
    } else if (this.isUserAdmin || this.isUserOffice) {
      this.getCourses({ wing: this.user.wing });
    }
  },
  props: {
    selectedCourse: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      courses: (state) => state.courses,
      addNewTestStatus: (state) => state.addNewTestStatus,
      addNewTestError: (state) => state.addNewTestError,
      isTestExist: (state) => state.isTestExist,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),

    testIdToShow() {
      if (this.test.title) {
        return `course_${this.selectedCourse}-test_${this.test.title
          .toLowerCase()
          .split(" ")
          .join("-")}`;
      }
      return "";
    },
    sumOfAllSubtests() {
      let sum = 0;
      if (this.test.subtests_available) {
        for (let subtest of this.test.subtests) {
          sum += subtest.total_marks;
        }
        return sum;
      }
      return 0;
    },
    marksTotalBalanced() {
      return (
        (this.sumOfAllSubtests == this.test.total_marks &&
          this.test.subtests_available) ||
        !this.test.subtests_available
      );
    },
    marksTotalError() {
      if (!this.marksTotalBalanced) {
        return "The total of subtests doesn't equal to the total marks of the test. Please recheck.";
      }
      return "";
    },
  },
  data() {
    return {
      test: {
        id: this.testIdToShow,
        title: "",
        total_marks: 0,
        weightage_marks: 0,
        subtests_available: false,
        sequence_number: 1,
        type: null,
        subtests_count: 0,
        subtests: [
          {
            id: "",
            title: "",
            weightage_marks: 0,
            total_marks: 0,
          },
        ],
      },
      testTypes: [
        {
          id: "written-test",
          title: "Written",
        },
        {
          id: "practical-test",
          title: "Practical",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["addNewTest", "getCourses", "checkIfTestExists"]),
    async addTest() {
      // Before Adding the test check if a test with same id exists or not. If it does then tell the user to change the test title. And don't continue to add a test. If the test id does not exist then go ahead and add the test.

      await this.checkIfTestExists({ id: this.testIdToShow });
      if (!this.isTestExist) {
        let courseDetails = this.courses.find(
          (c) => c.id == this.selectedCourse
        );
        this.addNewTest({
          ...this.test,
          id: this.testIdToShow,
          subtests: this.test.subtests_available ? this.test.subtests : [],
          course: courseDetails,
        });
        this.$emit("onOk");
      }
    },
    subtestIdToShow(subtest, index) {
      if (subtest.title) {
        this.test.subtests[index].id = `course_${
          this.selectedCourse
        }-test_${this.test.title
          .toLowerCase()
          .split(" ")
          .join("-")}-subtest_${index}_${subtest.title
          .toLowerCase()
          .split(" ")
          .join("-")}`;
        return `course_${this.selectedCourse}-test_${this.test.title
          .toLowerCase()
          .split(" ")
          .join("-")}-subtest_${index}_${subtest.title
          .toLowerCase()
          .split(" ")
          .join("-")}`;
      }
      return "";
    },
    addMoreSubtest() {
      this.test.subtests.push({
        id: "",
        title: "",
        total_marks: 0,
        weightage_marks: 0,
      });
    },
    discardCurrentSubtest(index) {
      this.test.subtests.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.test-id-style {
  color: cadetblue;
  font-style: italic;
}
.input-label {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>