import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDuISnqofmje90J17qwBWYIGQntNwE5sz0",
  authDomain: "cswt-training-result.firebaseapp.com",
  projectId: "cswt-training-result",
  storageBucket: "cswt-training-result.appspot.com",
  messagingSenderId: "402944127461",
  appId: "1:402944127461:web:9bf26dc23e15aa3c74bf0c",
  measurementId: "G-HKY8YV1N6N",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const functions = getFunctions(firebaseApp);
const storage = getStorage();

export {
  auth,
  db,
  functions,
  httpsCallable,
  RecaptchaVerifier,
  storage,
  ref,
  getDownloadURL,
};
