<template>
  <div class="users-list">
    <div
      class="d-flex align-items-end"
      :class="
        selectedCourse ? 'justify-content-between' : 'justify-content-start'
      "
    >
      <b-form-group
        id="courses-list-id"
        label="Select Course"
        label-for="courses-list"
        class="w-auto"
      >
        <b-form-select
          id="courses-list"
          v-model="selectedCourse"
          :options="courses"
          label-field="title"
          text-field="title"
          value-field="id"
          size="sm"
          class="form-control form-control-sm mr-4"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Course</b-form-select-option
            >
          </template>
        </b-form-select></b-form-group
      >
      <button
        v-if="selectedCourse"
        class="btn btn-sm btn-outline-brand margin-left-2"
        @click="openAddStudentModal"
      >
        Add Student
      </button>
      <AddStudent
        v-if="selectedCourse"
        ref="addStudentModal"
        id="add-student-modal"
        :title="'Add Student Details'"
        :selectedCourse="selectedCourse"
        @onOk="hideAddStudentModal"
        @onCancel="hideAddStudentModal"
      />
    </div>

    <div class="mt-3">
      <b-badge
        v-if="courseDetails"
        class="mr-2 px-2 py-1 badge-filters border-brand text-brand mb-2"
        pill
        >{{ this.courseDetails.title }}
        <span @click="selectedCourse = null">
          <i class="fa fa-regular fa-circle-xmark text-danger px-2"></i>
        </span>
      </b-badge>
      <b-badge
        class="px-2 py-1 badge-filters border-brand text-brand"
        :class="courseDetails ? 'mx-2' : ''"
        pill
        >{{ students.length }}
        {{ students.length > 1 ? "students" : "student" }} added</b-badge
      >
    </div>

    <div
      v-if="addNewStudentStatus === 'loading' || studentsStatus === 'loading'"
      class="container d-flex justify-content-center align-items-center flex-column bg-white rounded loading-page my-3 border font-xl"
    >
      <i class="fa-solid fa-spinner fa-spin"></i>
      <p class="mt-2">Fetching {{ isUserGod ? "users" : "students" }}</p>
    </div>
    <div
      v-else-if="
        addNewStudentStatus === 'failure' || studentsStatus === 'failure'
      "
    >
      Failed!!
    </div>
    <div v-else>
      <div
        v-if="!localStudentsList.length"
        class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page my-3 w-auto border"
      >
        <div class="pb-3 px-4 text-center">
          There are no users added to this course. Go ahead and add users.
        </div>
      </div>
      <div v-else class="table-container">
        <table
          class="table table-bordered border bg-white border-rounded w-100 rounded my-3"
        >
          <tr>
            <th class="col-student-irla-index"></th>
            <template v-for="field in courseSpecificStudentFields">
              <th :key="`student-view-${field.id}`">
                {{ field.title }}
              </th>
            </template>
          </tr>
          <tr
            v-for="(student, index) in localStudentsList"
            :key="`student-view-${student.key}`"
          >
            <td
              :key="`student-view-${student.key}`"
              class="col-student-irla-index"
            >
              {{ index + 1 }}
            </td>
            <template v-for="field in courseSpecificStudentFields">
              <td :key="`student-view-${student.key}-${field.id}`">
                {{
                  field.id == "course"
                    ? student[field.id]
                      ? student[field.id].title
                      : ""
                    : student[field.id]
                }}
              </td>
            </template>
            <td v-if="hasAccess(student)">
              <button
                class="btn btn-sm text-primary action-buttons"
                @click="openEditUserModal(student)"
              >
                Edit
                <!-- <i class="fa fa-xs fa-pencil pl-2 text-primary"></i> -->
              </button>
              <button
                class="btn btn-sm text-primary action-buttons"
                @click="openEditUserPhoneModal(student)"
              >
                {{ student.phone ? "Edit Phone" : "Link Phone" }}
                <!-- <i class="fa fa-xs fa-pencil pl-2 text-primary"></i> -->
              </button>
              <button
                class="btn btn-sm text-danger action-buttons"
                @click="openDeleteStudentConfirmationModal(student)"
              >
                Delete
                <!-- <i class="fa fa-xs fa-trash pl-2 text-danger"></i> -->
              </button>
            </td>
            <EditUser
              :ref="`edit-user-${student.key}-modal`"
              :id="student.key"
              :student="student"
              :title="'Edit User Details'"
              @onOk="hideEditUserModal(student)"
              @onCancel="hideEditUserModal(student)"
            />
            <ConfirmationModal
              :ref="`delete-student-${student.key}-modal`"
              :id="student.key"
              :title="'Remove Student'"
              :content="`Are you sure you want to remove this student - ${student.name}?`"
              :cancelButtonText="'Cancel'"
              :okButtonText="'Delete'"
              @onOk="deleteStudentDetails(student)"
              @onCancel="hideDeleteConfirmation(student)"
            />
            <EditUserPhoneNumber
              :ref="`edit-user-phone-${student.key}-modal`"
              :id="student.key"
              :student="student"
              :title="'Edit User Phone Number'"
              @onOk="hideEditUserPhoneModal(student)"
              @onCancel="hideEditUserPhoneModal(student)"
            />
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ConfirmationModal from "@/components/modal/ConfirmationModal.vue";
import EditUser from "@/components/modal/EditUser.vue";
import AddStudent from "@/components/modal/AddStudent.vue";
import EditUserPhoneNumber from "@/components/modal/EditUserPhoneNumber.vue";
import {
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BBadge,
} from "bootstrap-vue";
export default {
  name: "UsersPage",
  components: {
    ConfirmationModal,
    EditUser,
    AddStudent,
    EditUserPhoneNumber,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BBadge,
  },
  data() {
    return {
      selectedCourse: null,
      courseDetails: null,
      studentFields: [
        {
          id: "IRLA/Regimental Number",
          title: "IRLA",
        },
        {
          id: "name",
          title: "Name",
        },
        {
          id: "course",
          title: "Course",
        },
        {
          id: "Battalion",
          title: "Unit",
        },
        {
          id: "phone",
          title: "Phone",
        },
        {
          id: "Rank",
          title: "Rank",
        },
        {
          id: "role",
          title: "Access",
        },
      ],
      localStudents: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      students: (state) => state.students,
      studentsStatus: (state) => state.studentsStatus,
      studentsError: (state) => state.studentsError,
      addNewStudentStatus: (state) => state.addNewStudentStatus,
      courses: (state) => state.courses,
      currentCourse: (state) => state.currentCourse,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),
    localStudentsList: {
      get() {
        if (!this.isUserOffice) {
          return this.localStudents.filter(
            (student) => student.key == this.user.key
          );
        }
        return this.localStudents.sort(this.compareNumbers);
      },
      set(val) {
        this.localStudents = Array.from(val);
      },
    },
    courseSpecificStudentFields() {
      if (this.selectedCourse) {
        return this.studentFields.filter((field) => field.id !== "role");
      }
      return this.studentFields;
    },
  },
  beforeMount() {
    if (this.isUserGod) {
      this.getStudents({ isCourseSelected: false });
    } else if (this.isUserAdmin || this.isUserOffice) {
      this.getStudents({ isCourseSelected: false, wing: this.user.wing });
    }
  },
  mounted() {
    this.localStudentsList = JSON.parse(JSON.stringify(this.students));
  },
  methods: {
    ...mapActions(["getStudents", "deleteUser", "updateCurrentCourse"]),
    deleteStudentDetails(student) {
      this.deleteUser(student);
    },
    hideDeleteConfirmation(student) {
      this.$refs[`delete-student-${student.key}-modal`][0].$refs[
        `confirmation-modal-${student.key}`
      ].hide();
    },
    openDeleteStudentConfirmationModal(student) {
      this.$refs[`delete-student-${student.key}-modal`][0].$refs[
        `confirmation-modal-${student.key}`
      ].show();
    },
    hideEditUserModal(user) {
      this.$refs[`edit-user-${user.key}-modal`][0].$refs[
        `edit-modal-${user.key}`
      ].hide();
    },
    openEditUserModal(user) {
      this.$refs[`edit-user-${user.key}-modal`][0].$refs[
        `edit-modal-${user.key}`
      ].show();
    },
    hideAddStudentModal() {
      this.$refs.addStudentModal.$refs.addStudentModal.hide();
    },
    openAddStudentModal() {
      this.$refs.addStudentModal.$refs.addStudentModal.show();
    },
    hideEditUserPhoneModal(user) {
      this.$refs[`edit-user-phone-${user.key}-modal`][0].$refs[
        `edit-user-phone-modal-${user.key}`
      ].hide();
    },
    openEditUserPhoneModal(user) {
      this.$refs[`edit-user-phone-${user.key}-modal`][0].$refs[
        `edit-user-phone-modal-${user.key}`
      ].show();
    },
    hasAccess(userToBeEdited) {
      if (this.isUserOffice && userToBeEdited.role == "user") {
        return true;
      } else if (
        this.isUserAdmin &&
        (userToBeEdited.role == "user" || userToBeEdited.role == "office")
      ) {
        return true;
      } else if (this.isUserGod) {
        return true;
      } else return false;
    },
    compareNumbers(a, b) {
      //console.log('a.Battalion: ' + a.Battalion);
      //console.log('b.Battalion: ' + b.Battalion);
      //console.log('-------');
      if (typeof a.Battalion === "number" && typeof b.Battalion === "number") {
        return a.Battalion - b.Battalion;
      }
      // check for num vs string
      if (typeof a.Battalion === "number" && typeof b.Battalion === "string") {
        return -1;
      }
      // check for string vs num
      if (typeof a.Battalion === "string" && typeof b.Battalion === "number") {
        return 1;
      }
      // check for string vs string
      if (typeof a.Battalion === "string" && typeof b.Battalion === "string") {
        if (a.Battalion < b.Battalion) return -1;
        else return 1;
      }
      return 0;
    },
  },
  watch: {
    studentsStatus() {
      this.localStudentsList = JSON.parse(JSON.stringify(this.students));
    },
    selectedCourse(val) {
      console.log("selectedCourse watcher", val, this.courses);
      if (val) {
        this.courseDetails = this.courses.find((course) => course.id == val);
        this.updateCurrentCourse({ ...this.courseDetails });
        this.getStudents({
          course: this.courseDetails,
          isCourseSelected: true,
        });
      } else {
        this.courseDetails = null;
        this.updateCurrentCourse(null);
        if (this.isUserGod) {
          this.getStudents({ isCourseSelected: false });
        } else if (this.isUserAdmin || this.isUserOffice) {
          this.getStudents({
            isCourseSelected: false,
            wing: this.user.wing,
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow-x: auto;
}
.table {
  overflow: auto;
  width: auto;
  padding: 0 !important;
}
td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center !important;
}
th {
  /* min-width: 80px; */
  font-weight: 500;
  padding: 8px;
  text-align: center !important;
  /* border: 1px solid lightgrey; */
}
</style>