<template>
  <b-modal
    ref="addStudentModal"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
  >
    <div id="add-student-container">
      <b-form
        class="container justify-content-center w-auto"
        @submit.prevent="addNewStudent"
      >
        <!-- <div v-if="isUserOffice" class="pb-2 mb-3 border-bottom">
          <b-form-group
            id="selected-access-role-id"
            class="pb-3 col-4"
            label="Access"
            label-for="selected-access-role"
          >
            <b-form-select
              id="selected-access-role"
              v-model="selectedRole"
              :options="rolesList"
              label-field="title"
              text-field="title"
              value-field="id"
              size="sm"
              class="form-control form-control-sm"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select Access Permissions</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div> -->

        <!-- <b-form-group
          id="select-course-for-add-test-id"
          class="pb-3 col-4"
          label="Course"
          label-for="select-course-for-add-test"
        >
          <b-form-select
            id="select-course-for-add-test"
            v-model="selectedCourse"
            :options="courses"
            label-field="title"
            text-field="title"
            value-field="id"
            size="sm"
            class="form-control form-control-sm"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Select Course</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group> -->
        <b-form-group
          id="login-irla-number-id"
          class="pb-3"
          label="IRLA/Regimental Number"
          label-for="login-irla-number"
        >
          <b-form-input
            id="login-irla-number"
            v-model="student['IRLA/Regimental Number']"
            class="form-control form-control-sm"
            type="text"
            placeholder="Enter IRLA/Regimental Number"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="selected-rank-id"
          class="pb-3"
          label="Rank"
          label-for="selected-rank"
        >
          <b-form-select
            id="selected-rank"
            v-model="selectedRank"
            :options="ranksList"
            label-field="id"
            text-field="id"
            value-field="id"
            size="sm"
            class="form-control form-control-sm"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Select Rank</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group
          id="battalion-number-id"
          class="pb-3"
          label="Unit"
          label-for="battalion-number"
        >
          <b-form-input
            id="battalion-number"
            v-model.number="student['Battalion']"
            class="form-control form-control-sm"
            type="text"
            placeholder="Enter Unit"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="login-name-id"
          class="pb-3"
          label="Full Name"
          label-for="login-name"
        >
          <b-form-input
            id="login-name"
            v-model="student.name"
            class="form-control form-control-sm"
            type="text"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="login-email-id"
          class="pb-3"
          label="Login ID"
          label-for="login-email"
          description="Login ID is automatically assigned using IRLA number."
        >
          <b-form-input
            id="login-email"
            v-model="studentEmailToShow"
            class="form-control form-control-sm"
            type="email"
            placeholder="Enter Login ID"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn btn-sm px-3 bg-brand">
          Add Student
          <i
            v-if="addStudentStatus == 'loading'"
            class="fa-solid fa-spinner fa-spin pl-3"
          ></i
        ></b-button>
        <b-button class="btn btn-sm px-3 mx-2" type="reset">Reset</b-button>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  BTable,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
export default {
  name: "AddStudent",
  components: {
    BTable,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BModal,
  },
  props: {
    selectedCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      student: {
        email: this.studentEmailToShow,
        password: this.studentPasswordAutoSelect,
        name: null,
        phone: null,
        role: "user",
        "IRLA/Regimental Number": null,
        Rank: this.selectedRank,
        Battalion: null,
        course: null,
      },
      ranksList: ["CT", "HC", "ASI", "SI", "INSP", "AC", "DC", "2IC"],
      selectedRank: null,
      // selectedRole: null,
    };
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses,
      addStudentError: (state) => state.addStudentError,
      addStudentStatus: (state) => state.addStudentStatus,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),
    studentEmailToShow() {
      if (this.student["IRLA/Regimental Number"]) {
        return `${this.student["IRLA/Regimental Number"]}@cswt.in`;
      }
      return "";
    },
    studentPasswordAutoSelect() {
      if (this.student["IRLA/Regimental Number"]) {
        return this.student["IRLA/Regimental Number"];
      }
      return "";
    },
    // rolesList() {
    //   if (this.isUserGod) {
    //     return [
    //       {
    //         id: "admin",
    //         title: "Administrator",
    //       },
    //       {
    //         id: "office",
    //         title: "Office",
    //       },
    //       {
    //         id: "user",
    //         title: "Student",
    //       },
    //     ];
    //   } else if (this.isUserAdmin) {
    //     return [
    //       {
    //         id: "office",
    //         title: "Office",
    //       },
    //       {
    //         id: "user",
    //         title: "Student",
    //       },
    //     ];
    //   } else if (this.isUserOffice) {
    //     return [
    //       {
    //         id: "user",
    //         title: "Student",
    //       },
    //     ];
    //   }
    // },
  },
  methods: {
    ...mapActions(["signupAction"]),
    addNewStudent() {
      console.log("addNewStudent", {
        ...this.student,
        email: this.studentEmailToShow,
        password: this.studentPasswordAutoSelect,
        Rank: this.selectedRank,
      });
      let courseDetails = this.courses.find((c) => c.id == this.selectedCourse);
      // if (this.isUserOffice) {
      //   this.signupAction({
      //     ...this.student,
      //     email: this.studentEmailToShow,
      //     password: this.studentPasswordAutoSelect,
      //     Rank: this.selectedRank,
      //   });
      //   this.$emit("onOk");
      // } else {
      this.signupAction({
        ...this.student,
        email: this.studentEmailToShow,
        password: this.studentPasswordAutoSelect,
        Rank: this.selectedRank,
        course: courseDetails,
      });
      this.$emit("onOk");
      // }
    },
  },
};
</script>
<style scoped>
</style>
<style>
.form-group label {
  font-size: 14px;
}
</style>