<template>
  <div>
    <div class="d-flex justify-content-start align-items-center w-auto">
      <b-form-group
        id="courses-list-id"
        label="Course"
        label-for="courses-list"
        class="pb-3 w-auto"
      >
        <b-form-select
          id="courses-list"
          v-model="selectedCourse"
          :options="courses"
          label-field="title"
          text-field="title"
          value-field="id"
          size="sm"
          class="form-control form-control-sm mr-4"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Course</b-form-select-option
            >
          </template>
        </b-form-select></b-form-group
      >
      <b-form-group
        v-if="selectedCourse"
        id="selected-test-id"
        class="pb-3 w-auto mx-4"
        label="Test"
        label-for="selected-test"
      >
        <b-form-select
          id="selected-test"
          v-model="selectedTest"
          :options="getCourseTestsList"
          label-field="title"
          text-field="title"
          value-field="id"
          size="sm"
          class="form-control"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Test</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-start align-items-center">
        <b-badge
          v-if="selectedCourse"
          class="px-2 py-1 badge-filters border-brand text-brand mb-3"
          pill
          >{{ this.currentCourse.title }}
          <span @click="selectedCourse = null">
            <i class="fa fa-regular fa-circle-xmark text-danger px-2"></i>
          </span>
        </b-badge>
        <b-badge
          v-if="selectedCourse && selectedTest"
          class="mx-2 px-2 py-1 badge-filters border-brand text-brand mb-3"
          pill
          >{{ this.currentTest.title }}
          <span @click="selectedTest = null">
            <i class="fa fa-regular fa-circle-xmark text-danger px-2"></i>
          </span>
        </b-badge>
        <b-badge
          class="px-2 py-1 badge-filters border-brand text-brand mb-3"
          :class="selectedCourse && !selectedTest ? 'mx-2' : ''"
          pill
        >
          <span v-if="historyStatus !== 'loading'" class="">{{
            sortedHistory.length
          }}</span>
          <span v-else>
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
          <span class="mx-1"
            >{{ sortedHistory.length > 1 ? "activities" : "activity" }} till
            now</span
          >
        </b-badge>
      </div>
      <div v-if="historyStatus === 'loading'">
        <span class="font-md text-muted font-w500"
          >Last updated <i class="fa-solid fa-spinner fa-spin mx-3"></i
        ></span>
      </div>
      <div v-else-if="historyStatus === 'failure'">
        <span class="font-md text-muted font-w500"
          >Last updated <i class="fa fa-exclamation-triangle text-warning"></i
        ></span>
      </div>
      <div v-else-if="history.length">
        <span class="font-md text-muted font-w500"
          >Last updated {{ timeElapsed }}</span
        >
      </div>
    </div>
    <div
      v-if="historyStatus === 'loading'"
      class="container d-flex justify-content-center align-items-center flex-column bg-white rounded loading-page mb-3 border font-xl"
    >
      <i class="fa-solid fa-spinner fa-spin"></i>
      <p class="mt-2">Fetching recent activities</p>
    </div>
    <div
      v-else-if="historyStatus === 'failure'"
      class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page mb-3 border"
    >
      <div class="pb-3 px-4 text-center text-danger">
        <i class="fa fa-exclamation-triangle text-danger px-2 font-lg"></i>
        <p class="mb-0 font-lg">Get History request has failed.</p>
      </div>
      <button
        class="btn btn-sm btn-outline-brand margin-left-2"
        @click="onFetchHistoryAgain"
      >
        Try Again
      </button>
    </div>
    <div v-else>
      <div
        v-if="!sortedHistory.length"
        class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page mb-3 w-auto border"
      >
        <div class="pb-3 px-4 text-center">
          {{ getZeroHistoryMessage }}
        </div>
      </div>
      <div v-else>
        <div v-for="activity in sortedHistory" :key="activity.id">
          <div
            class="bg-white mb-2 border rounded"
            v-b-toggle="`collapse-${activity.id}`"
          >
            <div
              class="d-flex justify-content-between align-items-start flex-wrap"
            >
              <div
                class="px-4 py-3 d-flex justify-content-between flex-column align-items-start"
              >
                <!-- <p class="mb-0 font-lg">
                  <span>{{ activity.student.name }}'s</span>
                  <span class="font-style-italic px-2"
                    >marks have been updated for the test</span
                  >
                  <span>{{ activity.test.title }}</span>
                </p> -->
                <p class="mb-0 font-lg">
                  {{ getActivityTitle(activity) }}
                </p>
                <p class="mb-0 font-md text-muted">
                  <!-- <span class="text-dark">{{
                    activity.student["IRLA/Regimental Number"]
                  }}</span> -->
                  <span class="">
                    Course - {{ activity.student.course.title }}
                  </span>
                </p>
                <button
                  v-if="activity.test.subtests.length"
                  class="btn btn-sm text-primary show-subtests-button px-0 font-sm font-w500 pb-0"
                >
                  {{ activity.test.subtests.length }}
                  {{
                    activity.test.subtests.length > 1
                      ? "subtests updated"
                      : "subtest updated"
                  }}
                  <!-- <i class="fa fa-xs fa-angle-down text-primary"></i> -->
                </button>
              </div>
              <div
                class="px-4 py-3 d-flex justify-content-between flex-column align-items-end"
              >
                <p class="mb-0 font-lg text-primary">
                  <span class="mx-2">{{ activity.previousMarks }} </span>
                  <i class="fa-solid fa-arrow-right font-sm"></i>
                  <span class="mx-2">{{ activity.currentMarks }} </span>
                </p>
                <p class="mb-0 font-sm pt-1">
                  {{ modifiedDate(activity.modifiedAt) }}
                </p>
                <p class="mb-0 font-sm pt-1">
                  Updated by {{ activity.modifiedBy.name }}
                </p>
              </div>
            </div>

            <b-collapse
              v-if="activity.test.subtests.length"
              :id="`collapse-${activity.id}`"
              class="px-4 py-3 border-top"
            >
              <div
                v-for="subtest in activity.test.subtests"
                :key="subtest.id"
                class="font-sm"
              >
                <span>{{ subtest.title }}</span>
                <span class="mb-0 font-lg text-primary">
                  <span class="mx-2">{{ subtest.previousMarks }} </span>
                  <i class="fa-solid fa-arrow-right font-sm"></i>
                  <span class="mx-2">{{ subtest.currentMarks }} </span>
                </span>
              </div>
              <p
                v-if="
                  activity.currentTestState.subtests.length !==
                  activity.test.subtests.length
                "
                class="mb-0 font-md text-muted"
              >
                Out of the total
                {{ activity.currentTestState.subtests.length }} subtests, only
                {{ activity.test.subtests.length }}
                {{
                  activity.test.subtests.length > 1
                    ? "subtests are"
                    : "subtest is"
                }}
                updated for the student {{ activity.student.name }} ({{
                  activity.student["IRLA/Regimental Number"]
                }})
              </p>
              <p v-else class="mb-0 font-md text-muted">
                All of the
                {{ activity.currentTestState.subtests.length }} subtests are
                updated for the student {{ activity.student.name }} ({{
                  activity.student["IRLA/Regimental Number"]
                }})
              </p>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <!-- <div class="my-3 border rounded bg-white table-container">
      
      <b-table
        hover
        primary-key="modifiedAt"
        :items="history"
        :fields="fields"
        class="w-100 mb-0"
      ></b-table>
    </div> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  BTable,
  BButton,
  BCollapse,
  BCard,
  BBadge,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  VBToggle,
} from "bootstrap-vue";
// var moment = require("moment");
import moment from "moment-timezone";
export default {
  name: "HistoryPage",
  components: {
    BTable,
    BButton,
    BCollapse,
    BCard,
    BBadge,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
  },
  directives: { "b-toggle": VBToggle },

  data() {
    return {
      selectedCourse: null,
      selectedTest: null,
      fields: [
        {
          key: "test.title",
          label: "Test",
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "student.course.title",
          label: "Course",
          sortable: true,
          class: "text-center",
        },
        {
          key: "student.name",
          label: "Student",
          sortable: true,
          class: "text-center",
        },
        {
          key: "previousMarks",
          label: "Previous",
          sortable: true,
          class: "text-center",
        },
        {
          key: "currentMarks",
          label: "Updated",
          sortable: true,
          class: "text-center",
        },
        { key: "modifiedBy.name", label: "Modified By", class: "text-center" },
        { key: "modifiedAt", label: "Modified At", class: "text-center" },
      ],
      currentCourse: null,
      currentTest: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      tests: (state) => state.tests,
      courses: (state) => state.courses,
      history: (state) => state.history,
      historyStatus: (state) => state.historyStatus,
      historyError: (state) => state.historyError,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),
    getCourseTestsList() {
      return this.tests.filter(
        (t) => t.course && t.course.id === this.selectedCourse
      );
    },
    sortedHistory() {
      return this.history.length
        ? this.history.sort((a, b) => b.modifiedAt - a.modifiedAt)
        : [];
    },
    timeElapsed() {
      console.log("timeElapsed computed", this.sortedHistory[0]);
      return moment(this.sortedHistory[0].modifiedAt).fromNow();
    },
    getZeroHistoryMessage() {
      if (this.selectedTest) {
        return `There is no activity for this test - ${this.currentTest.title} of the course - ${this.currentCourse.title}`;
      } else if (this.selectedCourse) {
        return `There is no activity for this course - ${this.currentCourse.title}`;
      } else {
        if (!this.isUserGod) {
          return `There is no activity for the Wing - ${this.user.wing}`;
        } else {
          return `There is no activity across courses.`;
        }
      }
    },
  },
  beforeMount() {
    if (this.isUserGod) {
      this.getCourses();
    } else if (this.isUserAdmin || this.isUserOffice) {
      this.getCourses({ wing: this.user.wing });
    }
    this.getHistory();
  },
  methods: {
    ...mapActions(["getTests", "getCourses", "getHistory"]),
    modifiedDate(date) {
      return moment.tz(date, "Asia/Kolkata").format("MMMM Do YYYY, h:mm:ss a");
    },
    onFetchHistoryAgain() {
      if (this.selectedTest) {
        this.getHistory({ course: this.currentCourse, test: this.currentTest });
      } else if (this.selectedCourse) {
        this.getHistory({ course: this.currentCourse });
      } else {
        this.getHistory();
      }
    },
    getActivityTitle(activity) {
      return `Test ${activity.test.title} has been updated for the student ${activity.student.name}`;
    },
  },
  watch: {
    selectedCourse(val) {
      if (val) {
        this.currentCourse = this.courses.find((course) => course.id == val);
        this.getTests({ course: this.currentCourse, wing: this.user.wing });
        this.getHistory({ course: this.currentCourse });
      } else {
        if (this.isUserGod) {
          this.currentCourse = null;
          this.getTests();
          this.getHistory();
        } else {
          this.currentCourse = null;
          this.getTests({ wing: this.user.wing });
          this.getHistory({ wing: this.user.wing });
        }
      }
    },
    selectedTest(val) {
      if (val) {
        this.currentCourse = this.courses.find(
          (course) => course.id == this.selectedCourse
        );
        this.currentTest = this.tests.find((test) => test.id == val);
        this.getHistory({ course: this.currentCourse, test: this.currentTest });
      } else if (this.selectedCourse) {
        this.currentCourse = this.courses.find(
          (course) => course.id == this.selectedCourse
        );
        this.currentTest = null;
        this.getHistory({ course: this.currentCourse });
      }
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow-x: auto;
}
.table {
  overflow: auto;
  width: auto;
  padding: 0 !important;
}
</style>