import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from "vuex";
import store from "./store";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "handsontable/dist/handsontable.full.min.css";
import "./assets/main.css";

Vue.config.productionTip = false;

Vue.use(Vuex);

new Vue({
  router,
  render: (h) => h(App),
  store,
  beforeCreate() {
    this.$store.commit("initialiseStore");
    this.$store.dispatch("setDeveloperProfilePhoto");
    if (this.$store.getters.isUserGod) {
      this.$store.dispatch("getCourses");
    } else if (
      this.$store.getters.isUserAdmin ||
      this.$store.getters.isUserOffice
    ) {
      this.$store.dispatch("getCourses", { wing: this.$store.state.user.wing });
    }
  },
}).$mount("#app");
