<template>
  <b-modal
    :ref="`edit-modal-${student.key}`"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
  >
    <div id="edit-student-container">
      <b-form
        class="container justify-content-center w-auto"
        @submit.prevent="onEditUser"
      >
        <label for="select-course-for-edit-student" class="input-label"
          >Course</label
        ><b-form-select
          id="select-course-for-edit-student"
          v-model="selectedCourse"
          :options="courses"
          label-field="title"
          text-field="title"
          value-field="id"
          size="sm"
          class="form-control form-control-sm w-25 mb-4 col"
          disabled
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Select Course</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-form-group
          id="login-irla-number-id"
          class="pb-3"
          label="IRLA/Regimental Number"
          label-for="login-irla-number"
        >
          <b-form-input
            id="login-irla-number"
            v-model="student['IRLA/Regimental Number']"
            type="text"
            placeholder="Enter IRLA/Regimental Number"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="selected-rank-id"
          class="pb-3"
          label="Rank"
          label-for="selected-rank"
        >
          <b-form-select
            id="selected-rank"
            v-model="selectedRank"
            :options="ranksList"
            label-field="id"
            text-field="id"
            value-field="id"
            size="sm"
            class="form-control form-control-sm"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Select Rank</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group
          id="battalion-number-id"
          class="pb-3"
          label="Unit"
          label-for="battalion-number"
        >
          <b-form-input
            id="battalion-number"
            v-model.number="student['Battalion']"
            type="text"
            placeholder="Enter Unit"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="login-name-id"
          class="pb-3"
          label="Full Name"
          label-for="login-name"
        >
          <b-form-input
            id="login-name"
            v-model="student.name"
            type="text"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="login-email-id"
          class="pb-3"
          label="Login ID"
          label-for="login-email"
          description="Login ID is automatically assigned using IRLA number. It can't be edited."
        >
          <b-form-input
            id="login-email"
            v-model="studentEmailToShow"
            type="email"
            placeholder="Enter Login ID"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn btn-sm px-3 bg-brand">
          Edit Student
          <i
            v-if="addStudentStatus == 'loading'"
            class="fa-solid fa-spinner fa-spin pl-3"
          ></i
        ></b-button>
        <!-- <b-button class="btn btn-sm px-3 mx-2" type="reset">Reset</b-button> -->
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  BTable,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
export default {
  name: "EditUser",
  props: {
    student: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BTable,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BModal,
  },
  data() {
    return {
      selectedCourse: this.student.course ? this.student.course.id : null,
      ranksList: ["CT", "HC", "ASI", "SI", "INSP", "AC", "DC", "2IC"],
      selectedRank: this.student["Rank"] || null,
    };
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses,
      addStudentError: (state) => state.addStudentError,
      addStudentStatus: (state) => state.addStudentStatus,
    }),
    studentEmailToShow() {
      if (this.student["IRLA/Regimental Number"]) {
        return `${this.student["IRLA/Regimental Number"]}@cswt.in`;
      }
      return "";
    },
    finalCourse() {
      return this.student.course ? this.student.course.id : null;
    },
  },
  methods: {
    ...mapActions(["editUserInList"]),
    async onEditUser() {
      let courseDetails = this.courses.find(
        (course) => course.id == this.selectedCourse
      );
      if (courseDetails) {
        console.log("onEditUser component", {
          ...this.student,
          Rank: this.selectedRank,
          course: courseDetails,
        });
        await this.editUserInList({
          ...this.student,
          Rank: this.selectedRank,
          course: courseDetails,
        });
      } else {
        await this.editUserInList({
          ...this.student,
          Rank: this.selectedRank,
        });
      }
      this.$emit("onOk");
    },
  },
};
</script>
<style scoped>
.input-label {
  font-size: 14px;
}
</style>
<style>
.form-group label {
  font-size: 14px;
}
</style>