<template>
  <b-modal
    ref="resetPasswordModal"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
    v-on:close="onClose"
  >
    <b-form @submit.prevent="onResetPassowrd">
      <b-form-group
        id="user-password-id"
        class="pb-3"
        label="New Password"
        label-for="user-password"
      >
        <b-form-input
          id="user-password"
          v-model="newPassword"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" class="bg-brand mb-2"
        >Reset
        <i
          v-show="status == 'loading'"
          class="fa-solid fa-spinner fa-spin pl-3"
        ></i
      ></b-button>
      <p v-if="error" class="text-danger mb-0 error-message">
        <i class="fa-solid fa-triangle-exclamation pr-3"></i>{{ error }}
      </p>
    </b-form>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm, BModal } from "bootstrap-vue";
export default {
  name: "ResetPasswordModal",
  data() {
    return {
      newPassword: null,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.status,
      error: (state) => state.error,
    }),
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
  },
  methods: {
    ...mapActions(["resetPassword", "resetStatesOnPasswordReset"]),
    async onResetPassowrd() {
      console.log("onResetPassowrd", this.newPassword);
      await this.resetPassword(this.newPassword);
      this.$emit("onOk");
    },
    onClose() {
      this.newPassword = null;
      this.resetStatesOnPasswordReset();
    },
  },
};
</script>
<style scoped>
.error-message {
  font-size: 12px;
}
</style>