<template>
  <div class="courses-container">
    <div
      v-if="status === 'loading' || userStatus === 'loading'"
      class="container d-flex justify-content-center align-items-center bg-white rounded loading-page"
    >
      <i class="fa-solid fa-spinner fa-spin"></i>
    </div>
    <div v-else-if="status === 'failure' || userStatus === 'failure'">
      Failed!!
    </div>
    <div v-else>
      <div
        v-if="!isUserOffice"
        class="mb-2 bg-white px-3 py-2 w-100 d-flex justify-content-between align-items-center rounded"
      >
        <div class="user-name">
          <div>{{ user ? user.name : "" }}</div>
          <div>{{ user ? user["IRLA/Regimental Number"] : "" }}</div>
        </div>
        <div>
          <b-badge
            class="px-3 py-3 mb-2"
            :class="{
              'bg-a-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) == 'a',
              'bg-b-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) == 'b',
              'bg-c-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) == 'c',
              'bg-delta-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) == 'delta',
              'bg-fail-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) == 'fail',
              'text-white':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) == 'fail',
              'text-black':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllWrittenTests,
                  this.getTotalObtainedMarksWrittenCurrent
                ) != 'fail',
            }"
            pill
          >
            {{ getCurrentGradingWritten }}
          </b-badge>
          <b-badge
            class="mx-2 px-3 py-3 mb-2"
            :class="{
              'bg-a-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) == 'a',
              'bg-b-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) == 'b',
              'bg-c-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) == 'c',
              'bg-delta-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) == 'delta',
              'bg-fail-grading':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) == 'fail',
              'text-white':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) == 'fail',
              'text-black':
                getColorVariantForPercentage(
                  this.getTotalMarksOfAllPracticalTests,
                  this.getTotalObtainedMarksPracticalCurrent
                ) != 'fail',
            }"
            pill
          >
            {{ getCurrentGradingPractical }}
          </b-badge>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-end mb-3">
        <div
          v-if="isUserOffice"
          class="d-flex justify-content-start align-items-center w-auto"
        >
          <b-form-group
            v-if="isUserGod"
            id="wings-list-id"
            label="Wing"
            label-for="wings-list"
          >
            <b-form-select
              id="wings-list"
              v-model="selectedWing"
              :options="wings"
              label-field="title"
              text-field="title"
              value-field="id"
              size="sm"
              class="form-control"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select Wing</b-form-select-option
                >
              </template>
            </b-form-select></b-form-group
          >
          <b-form-group
            id="courses-list-id"
            label="Course"
            label-for="courses-list"
            :class="isUserGod ? 'mx-4' : 'mr-4'"
          >
            <b-form-select
              id="courses-list"
              v-model="selectedCourse"
              :options="courses"
              label-field="title"
              text-field="title"
              value-field="id"
              size="sm"
              class="form-control"
              :disabled="isUserGod && !selectedWing"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select Course</b-form-select-option
                >
              </template>
            </b-form-select></b-form-group
          >
          <b-form-group
            v-if="selectedCourse && localStudentsList.length"
            id="courses-view-type-id"
            label="Select view"
            label-for="courses-view-type"
            class="mx-4"
          >
            <b-form-select
              id="courses-view-type"
              v-model="selectedViewType"
              :options="viewTypes"
              label-field="title"
              text-field="title"
              value-field="id"
              size="sm"
              class="form-control"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select View</b-form-select-option
                >
              </template>
            </b-form-select></b-form-group
          >
        </div>
        <div
          v-if="isUserOffice"
          class="d-flex justify-content-end align-items-center"
        >
          <!-- <button
            class="btn btn-sm btn-outline-brand"
            @click="openAddCourseModal"
          >
            Add Course
          </button>
          <AddCourse
            ref="addCourseModal"
            id="add-course-modal"
            :title="'Add Course Details'"
            @onOk="hideAddCourseModal"
            @onCancel="hideAddCourseModal"
          /> -->
          <!-- <button
            v-if="selectedViewType == 'students'"
            class="btn btn-sm btn-outline-brand margin-left-2"
            @click="openAddStudentModal"
          >
            Add Student
          </button>
          <AddStudent
            ref="addStudentModal"
            id="add-student-modal"
            :title="'Add Student Details'"
            @onOk="hideAddStudentModal"
            @onCancel="hideAddStudentModal"
          /> -->
        </div>
      </div>
      <div
        v-if="studentsStatus == 'loading'"
        class="container d-flex justify-content-center align-items-center bg-white rounded loading-page"
      >
        <i class="fa-solid fa-spinner fa-spin"></i>
      </div>
      <div v-else-if="studentsStatus == 'failure'">
        {{ studentsError }} Try again
      </div>
      <div v-else>
        <div
          v-if="!localStudentsList.length && selectedCourse"
          class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page"
        >
          <div class="mb-2">There are no students added to this course.</div>
          <div class="mb-2">Go ahead and add students.</div>
          <button
            class="btn btn-sm btn-outline-brand margin-left-2"
            @click="navigateToUsers"
          >
            Go to Users Page
          </button>
        </div>
        <div v-else>
          <div
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="d-flex justify-content-start align-items-center">
              <b-form-group
                v-if="selectedCourse && selectedViewType == 'results'"
                id="test-type-id"
                class="pb-3 w-auto"
                label="Select test type"
                label-for="test-type"
              >
                <b-form-select
                  id="test-type"
                  v-model="selectedTestType"
                  :options="testTypes"
                  label-field="title"
                  text-field="title"
                  value-field="id"
                  size="sm"
                  class="form-control"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >Select Test Type</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                v-if="selectedTestType"
                id="selected-test-id"
                class="pb-3 w-auto mx-4"
                label="Select test"
                label-for="selected-test"
              >
                <b-form-select
                  id="selected-test"
                  v-model="selectedTest"
                  :options="getFinalTestsList"
                  label-field="title"
                  text-field="title"
                  value-field="id"
                  size="sm"
                  class="form-control"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >Select Test</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <b-form-group
              v-if="selectedCourse && selectedTestType"
              id="gradings-list-id"
              class="w-auto mr-4 mb-3"
              label="Desired Grading"
              label-for="gradings-list"
            >
              <b-form-select
                id="gradings-list"
                v-model="selectedGrading"
                :options="getGradingsList"
                label-field="title"
                text-field="title"
                value-field="id"
                size="sm"
                class="form-control"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select Grading</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="container bg-white rounded">
            <div
              v-if="
                selectedCourse &&
                selectedViewType == 'results' &&
                [...getWrittenTestsList, ...getPracticalTestsList].length
              "
              class="d-flex align-items-start py-3"
              :class="`justify-content-${
                selectedCourse || selectedTestType || selectedTest
                  ? 'between'
                  : 'end'
              }`"
            >
              <div class="mb-3">
                <b-badge
                  v-if="isUserOffice"
                  class="mr-2 px-2 py-1 badge-filters border-brand text-brand mb-2"
                  pill
                  >{{ this.currentCourse.title }}
                  <span @click="selectedCourse = null">
                    <i
                      class="fa fa-regular fa-circle-xmark text-danger px-2"
                    ></i>
                  </span>
                </b-badge>
                <b-badge
                  v-if="selectedTestType"
                  class="mr-2 px-2 py-1 badge-filters border-brand text-brand mb-2"
                  pill
                  >{{
                    this.testTypes.find(
                      (type) => type.id == this.selectedTestType
                    ).title
                  }}
                  <span
                    @click="
                      selectedTestType = null;
                      selectedTest = null;
                    "
                  >
                    <i
                      class="fa fa-regular fa-circle-xmark text-danger px-2"
                    ></i>
                  </span>
                </b-badge>
                <b-badge
                  v-if="selectedTest"
                  class="mr-2 px-2 py-1 badge-filters border-brand text-brand mb-2"
                  pill
                  >{{
                    this.tests.find((test) => test.id == this.selectedTest)
                      .title
                  }}
                  <span @click="selectedTest = null">
                    <i
                      class="fa fa-regular fa-circle-xmark text-danger px-2"
                    ></i>
                  </span>
                </b-badge>
              </div>
              <div
                class="d-flex justify-content-end align-items-center grading-color-info mb-2 flex-wrap"
              >
                <b-badge
                  class="mr-2 px-2 py-1 bg-delta-grading text-black mb-2"
                  pill
                >
                  D >= 80%
                </b-badge>
                <b-badge
                  class="mr-2 px-2 py-1 bg-a-grading text-black mx-2 mb-2"
                  pill
                >
                  80% > A/X >= 70%</b-badge
                >
                <b-badge
                  class="mr-2 px-2 py-1 bg-b-grading text-black mx-2 mb-2"
                  pill
                >
                  70% > B/Y >= 60%</b-badge
                >
                <b-badge
                  class="mr-2 px-2 py-1 bg-c-grading text-black mx-2 mb-2"
                  pill
                >
                  60% > C/Z >= 45%</b-badge
                >
                <b-badge
                  class="mr-2 px-2 py-1 bg-fail-grading text-white mb-2"
                  pill
                >
                  Fail &lt; 45%</b-badge
                >
              </div>
            </div>

            <div class="table-container">
              <div v-if="!isUserOffice">
                <table
                  class="user-result-table table table-bordered border bg-white border-rounded w-100"
                >
                  <tr class="table-heading-row table-heading-bg">
                    <th
                      class="col-user-test-index col-user-test-name-heading-index"
                    ></th>
                    <th class="col-user-test-name col-user-test-name-heading">
                      Tests
                    </th>
                    <th>Total Marks</th>
                    <th>Obtained Marks</th>
                    <th v-if="selectedTestType">Difference</th>
                  </tr>
                  <tr v-if="!selectedTestType" class="table-dummy-row bg-light">
                    <td class="table-dummy-row-column"></td>
                    <td class="col-user-test-name-type">Written</td>
                    <td></td>
                    <td></td>
                    <td v-if="selectedTestType"></td>
                  </tr>

                  <tr
                    class="table-test-row"
                    v-for="(test, index) in getWrittenTestsList"
                    :key="`student-test-${test.id}`"
                  >
                    <td class="col-user-test-index">{{ index + 1 }}</td>
                    <td class="col-user-test-name">{{ test.title }}</td>
                    <td>{{ test.weightage_marks }}</td>
                    <td
                      :class="`bg-${getColorVariantForPercentage(
                        getTestMarks(user, test).weightage_marks,
                        getTestMarks(user, test).obtained_marks
                      )}-grading`"
                    >
                      {{ getTestMarks(user, test).obtained_marks }}
                    </td>
                    <td
                      v-if="selectedTestType"
                      :class="`font-${
                        getCalculatedShortMarks(
                          getTestMarks(user, test).weightage_marks,
                          getTestMarks(user, test).obtained_marks
                        ) >= 0
                          ? 'positive'
                          : 'negative'
                      }-marks short-marks-column`"
                    >
                      {{
                        getCalculatedShortMarks(
                          getTestMarks(user, test).weightage_marks,
                          getTestMarks(user, test).obtained_marks
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-if="showWrittenTests"
                    class="table-total-row table-heading-bg"
                  >
                    <td
                      class="col-user-test-index col-user-test-name-heading-index"
                    ></td>
                    <td class="col-user-test-name col-user-test-name-total">
                      Total
                    </td>
                    <td>{{ getTotalOfWrittenTests }}</td>
                    <td
                      :class="`bg-${getColorVariantForPercentage(
                        getTotalOfWrittenTests,
                        getTotalObtainedMarks(user, 'written-test')
                      )}-grading`"
                    >
                      {{ getTotalObtainedMarks(user, "written-test") }}
                    </td>
                    <td
                      v-if="selectedTestType"
                      :class="`font-${
                        getTotalShortMarks(user, 'written-test') >= 0
                          ? 'positive'
                          : 'negative'
                      }-marks short-marks-column`"
                    >
                      {{ getTotalShortMarks(user, "written-test") }}
                    </td>
                  </tr>

                  <tr v-if="!selectedTestType" class="table-dummy-row bg-light">
                    <td class="table-dummy-row-column"></td>
                    <td class="col-user-test-name-type">Practical</td>
                    <td></td>
                    <td></td>
                    <td v-if="selectedTestType"></td>
                  </tr>
                  <tr
                    class="table-test-row"
                    v-for="(test, index) in getPracticalTestsList"
                    :key="`student-test-${test.id}`"
                  >
                    <td class="col-user-test-index">{{ index + 1 }}</td>
                    <td class="col-user-test-name">{{ test.title }}</td>
                    <td>{{ test.weightage_marks }}</td>
                    <td
                      :class="`bg-${getColorVariantForPercentage(
                        getTestMarks(user, test).weightage_marks,
                        getTestMarks(user, test).obtained_marks
                      )}-grading`"
                    >
                      {{ getTestMarks(user, test).obtained_marks }}
                    </td>
                    <td
                      v-if="selectedTestType"
                      :class="`font-${
                        getCalculatedShortMarks(
                          getTestMarks(user, test).weightage_marks,
                          getTestMarks(user, test).obtained_marks
                        ) >= 0
                          ? 'positive'
                          : 'negative'
                      }-marks short-marks-column`"
                    >
                      {{
                        getCalculatedShortMarks(
                          getTestMarks(user, test).weightage_marks,
                          getTestMarks(user, test).obtained_marks
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-if="showPracticalTests"
                    class="table-total-row table-heading-bg"
                  >
                    <td
                      class="col-user-test-index col-user-test-name-heading-index"
                    ></td>
                    <td class="col-user-test-name col-user-test-name-total">
                      Total
                    </td>
                    <td>{{ getTotalOfPracticalTests }}</td>
                    <td
                      :class="`bg-${getColorVariantForPercentage(
                        getTotalOfPracticalTests,
                        getTotalObtainedMarks(user, 'practical-test')
                      )}-grading`"
                    >
                      {{ getTotalObtainedMarks(user, "practical-test") }}
                    </td>
                    <td
                      v-if="selectedTestType"
                      :class="`font-${
                        getTotalShortMarks(user, 'practical-test') >= 0
                          ? 'positive'
                          : 'negative'
                      }-marks short-marks-column`"
                    >
                      {{ getTotalShortMarks(user, "practical-test") }}
                    </td>
                  </tr>
                </table>
              </div>
              <template
                v-else-if="selectedCourse && selectedViewType == 'results'"
              >
                <div
                  v-if="
                    ![...getWrittenTestsList, ...getPracticalTestsList].length
                  "
                  class="container d-flex flex-column justify-content-center align-items-center bg-white rounded loading-page"
                >
                  <div class="mb-2">
                    There are no tests added to this course.
                  </div>
                  <div class="mb-2">Go ahead and add tests.</div>
                  <button
                    class="btn btn-sm btn-outline-brand margin-left-2"
                    @click="openTestsPage"
                  >
                    Go to Tests Page
                  </button>
                </div>
                <table
                  v-else
                  class="table table-bordered border bg-white border-rounded"
                >
                  <tr id="results-course-header">
                    <th class="col-student-irla-index"></th>
                    <th class="col-student-irla"></th>
                    <th class="col-student-name"></th>
                    <template v-for="test in getWrittenTestsList">
                      <th
                        :key="`${test.id}-1`"
                        @click="getSubtestsForTest(test)"
                        :class="{
                          'cursor-pointer': test.subtests_available,
                          'test-heading-bg-written': true,
                        }"
                      >
                        {{ test.title }}
                      </th>
                      <th
                        v-if="!selectedTest && selectedTestType"
                        :key="`${test.id}-2`"
                        :class="{
                          'cursor-pointer': test.subtests_available,
                          'test-heading-bg-written': true,
                        }"
                      >
                        {{ test.weightage_marks }}
                      </th>
                    </template>
                    <th
                      v-if="showWrittenTests && getWrittenTestsList.length"
                      class="test-heading-bg-written"
                    >
                      Sum Total
                    </th>
                    <th
                      v-if="showWrittenTests && selectedTestType"
                      class="test-heading-bg-written short-marks-column"
                    >
                      {{ getTotalOfWrittenTests || "" }}
                    </th>
                    <template v-for="test in getPracticalTestsList">
                      <th
                        :key="`${test.id}-1`"
                        @click.prevent="getSubtestsForTest(test)"
                        :class="{
                          'cursor-pointer': test.subtests_available,
                          'test-heading-bg-practical': true,
                        }"
                      >
                        {{ test.title }}
                      </th>
                      <th
                        v-if="!selectedTest && selectedTestType"
                        :key="`${test.id}-2`"
                        :class="{
                          'cursor-pointer': test.subtests_available,
                          'test-heading-bg-practical': true,
                        }"
                      >
                        {{ test.weightage_marks }}
                      </th>
                    </template>
                    <th
                      v-if="showPracticalTests && getPracticalTestsList.length"
                      class="test-heading-bg-practical"
                    >
                      Sum Total
                    </th>
                    <th
                      v-if="showPracticalTests && selectedTestType"
                      class="test-heading-bg-practical short-marks-column"
                    >
                      {{ getTotalOfPracticalTests || "" }}
                    </th>
                    <th>Grading</th>
                  </tr>
                  <tr class="mb-3 border-bottom second-heading">
                    <th class="col-student-irla-index"></th>
                    <th class="col-student-irla">IRLA</th>
                    <th class="col-student-name">Name</th>

                    <template v-for="test in getWrittenTestsList">
                      <th
                        v-if="
                          selectedTest || (!selectedTest && !selectedTestType)
                        "
                        :key="`${test.id}-1`"
                      >
                        {{ test.weightage_marks }}
                      </th>
                      <th v-else :key="`${test.id}-1`">Obtained marks</th>
                      <th
                        v-if="!selectedTest && selectedTestType"
                        :key="`${test.id}-2`"
                        class="short-marks-column"
                      >
                        Difference
                      </th>
                    </template>
                    <template
                      v-if="!selectedTestType && getWrittenTestsList.length"
                    >
                      <th>
                        {{ getTotalOfWrittenTests }}
                      </th>
                    </template>
                    <template
                      v-else-if="
                        selectedTestType &&
                        selectedTestType == 'written-test' &&
                        getWrittenTestsList.length
                      "
                    >
                      <th>Obtained marks</th>
                      <th class="short-marks-column">Difference</th>
                    </template>
                    <!-- <th v-if="showWrittenTests">Obtained marks</th>
            <template v-else>
              <th>
                {{ getTotalOfWrittenTests }}
              </th>
              <th v-if="showWrittenTests && selectedTestType == 'written-test'">
                Difference
              </th>
            </template> -->

                    <template v-for="test in getPracticalTestsList">
                      <th
                        v-if="
                          selectedTest || (!selectedTest && !selectedTestType)
                        "
                        :key="`${test.id}-1`"
                      >
                        {{ test.weightage_marks }}
                      </th>
                      <th v-else :key="`${test.id}-1`">Obtained marks</th>
                      <th
                        v-if="!selectedTest && selectedTestType"
                        :key="`${test.id}-2`"
                        class="short-marks-column"
                      >
                        Difference
                      </th>
                    </template>
                    <template
                      v-if="!selectedTestType && getPracticalTestsList.length"
                    >
                      <th>
                        {{ getTotalOfPracticalTests }}
                      </th>
                    </template>
                    <template
                      v-else-if="
                        selectedTestType &&
                        selectedTestType == 'practical-test' &&
                        getPracticalTestsList.length
                      "
                    >
                      <th>Obtained marks</th>
                      <th class="short-marks-column">Difference</th>
                    </template>
                    <th></th>
                  </tr>
                  <tr
                    v-for="(student, index) in localStudentsList"
                    :key="student['IRLA/Regimental Number']"
                  >
                    <td class="col-student-irla-index">{{ index + 1 }}</td>
                    <td class="col-student-irla">
                      {{ student["IRLA/Regimental Number"] }}
                    </td>
                    <td class="col-student-name">
                      <div id="content">{{ student.name }}</div>
                    </td>
                    <template v-for="test in getWrittenTestsList">
                      <td
                        v-if="!selectedTest"
                        :key="`student-${student.key}-test-${test.id}-1`"
                        :class="`bg-${getColorVariantForPercentage(
                          getTestMarks(student, test).weightage_marks,
                          getTestMarks(student, test).obtained_marks
                        )}-grading`"
                      >
                        {{ getTestMarks(student, test).obtained_marks }}
                      </td>
                      <template v-else>
                        <td
                          v-if="!isUserOffice"
                          :key="`student-${student.key}-test-${test.id}-1`"
                          :class="`bg-${getColorVariantForPercentage(
                            getTestMarks(student, test).weightage_marks,
                            getTestMarks(student, test).obtained_marks
                          )}-grading`"
                        >
                          {{ getTestMarks(student, test).obtained_marks }}
                        </td>
                        <template v-else>
                          <td :key="`student-${student.key}-test-${test.id}-1`">
                            <b-input-group
                              :key="`student-${student.key}-test-${test.id}-edit-1`"
                              class="marks-input-edit"
                            >
                              <b-form-input
                                :ref="`student-${student.key}-test-${test.id}-1`"
                                size="sm"
                                v-model.number="
                                  getTestMarks(student, test).obtained_marks
                                "
                                type="number"
                                :max="getTestMarks(student, test).total_marks"
                                class="marks-input"
                                @keyup.enter.prevent.stop="
                                  onEditByEnterOrTab($event, student, test)
                                "
                                :disabled="!isUserOffice"
                              ></b-form-input>
                              <template #append>
                                <span
                                  @click="
                                    onEditMarks(
                                      student,
                                      getTestMarks(student, test)
                                    )
                                  "
                                  class="edit-button"
                                >
                                  <i class="fa fa-xs fa-check text-success"></i
                                ></span>
                                <span
                                  @click="
                                    onCancelEdit(
                                      student,
                                      getTestMarks(student, test)
                                    )
                                  "
                                  class="cancel-button pl-2"
                                >
                                  <i class="fa fa-xs fa-x text-danger"></i
                                ></span>
                              </template>
                            </b-input-group>
                          </td>
                        </template>
                      </template>

                      <td
                        v-if="!selectedTest && selectedTestType"
                        :key="`student-${student.key}-test-${test.id}-2`"
                        :class="`font-${
                          getCalculatedShortMarks(
                            getTestMarks(student, test).weightage_marks,
                            getTestMarks(student, test).obtained_marks,
                            selectedGrading
                          ) >= 0
                            ? 'positive'
                            : 'negative'
                        }-marks short-marks-column`"
                      >
                        {{
                          getCalculatedShortMarks(
                            getTestMarks(student, test).weightage_marks,
                            getTestMarks(student, test).obtained_marks,
                            selectedGrading
                          )
                        }}
                      </td>
                    </template>
                    <td
                      v-if="showWrittenTests && getWrittenTestsList.length"
                      :class="`bg-${getColorVariantForPercentage(
                        getTotalOfWrittenTests,
                        getTotalObtainedMarks(student, 'written-test')
                      )}-grading`"
                    >
                      {{ getTotalObtainedMarks(student, "written-test") || 0 }}
                    </td>
                    <td
                      v-if="
                        showWrittenTests &&
                        selectedTestType &&
                        getWrittenTestsList.length
                      "
                      :class="`font-${
                        (getTotalShortMarks(student, 'written-test') || 0) >= 0
                          ? 'positive'
                          : 'negative'
                      }-marks short-marks-column`"
                    >
                      {{ getTotalShortMarks(student, "written-test") || 0 }}
                    </td>
                    <template v-for="test in getPracticalTestsList">
                      <td
                        v-if="!selectedTest"
                        :key="`student-${student.key}-test-${test.id}-1`"
                        :class="`bg-${getColorVariantForPercentage(
                          getTestMarks(student, test).weightage_marks,
                          getTestMarks(student, test).obtained_marks
                        )}-grading`"
                      >
                        {{ getTestMarks(student, test).obtained_marks }}
                      </td>
                      <template v-else>
                        <td
                          v-if="!isUserOffice"
                          :key="`student-${student.key}-test-${test.id}-1`"
                          :class="`bg-${getColorVariantForPercentage(
                            getTestMarks(student, test).weightage_marks,
                            getTestMarks(student, test).obtained_marks
                          )}-grading`"
                        >
                          {{ getTestMarks(student, test).obtained_marks }}
                        </td>
                        <template v-else>
                          <td :key="`student-${student.key}-test-${test.id}-1`">
                            <b-input-group
                              :key="`student-${student.key}-test-${test.id}-edit-1`"
                              class="marks-input-edit"
                            >
                              <b-form-input
                                :ref="`student-${student.key}-test-${test.id}-1`"
                                size="sm"
                                v-model.number="
                                  getTestMarks(student, test).obtained_marks
                                "
                                :max="getTestMarks(student, test).total_marks"
                                type="number"
                                class="marks-input"
                                @keyup.enter.prevent.stop="
                                  onEditByEnterOrTab($event, student, test)
                                "
                                :disabled="!isUserOffice"
                              ></b-form-input>
                              <template #append>
                                <span
                                  @click="
                                    onEditMarks(
                                      student,
                                      getTestMarks(student, test)
                                    )
                                  "
                                  class="edit-button"
                                >
                                  <i class="fa fa-xs fa-check text-success"></i
                                ></span>
                                <span
                                  @click="
                                    onCancelEdit(
                                      student,
                                      getTestMarks(student, test)
                                    )
                                  "
                                  class="cancel-button pl-2"
                                >
                                  <i class="fa fa-xs fa-x text-danger"></i
                                ></span>
                              </template>
                            </b-input-group>
                          </td>
                        </template>
                      </template>

                      <td
                        v-if="!selectedTest && selectedTestType"
                        :key="`student-${student.key}-test-${test.id}-2`"
                        :class="`font-${
                          getCalculatedShortMarks(
                            getTestMarks(student, test).weightage_marks,
                            getTestMarks(student, test).obtained_marks,
                            selectedGrading
                          ) >= 0
                            ? 'positive'
                            : 'negative'
                        }-marks short-marks-column`"
                      >
                        {{
                          getCalculatedShortMarks(
                            getTestMarks(student, test).weightage_marks,
                            getTestMarks(student, test).obtained_marks
                          )
                        }}
                      </td>
                    </template>
                    <td
                      v-if="showPracticalTests && getPracticalTestsList.length"
                      :class="`bg-${getColorVariantForPercentage(
                        getTotalOfPracticalTests,
                        getTotalObtainedMarks(student, 'practical-test')
                      )}-grading`"
                    >
                      {{
                        getTotalObtainedMarks(student, "practical-test") || 0
                      }}
                    </td>
                    <td
                      v-if="
                        showPracticalTests &&
                        selectedTestType &&
                        getPracticalTestsList.length
                      "
                      :class="`font-${
                        (getTotalShortMarks(student, 'practical-test') || 0) >=
                        0
                          ? 'positive'
                          : 'negative'
                      }-marks short-marks-column`"
                    >
                      {{ getTotalShortMarks(student, "practical-test") || 0 }}
                    </td>
                    <td>
                      <template class="grading-badges">
                        <b-badge
                          v-if="
                            !selectedTestType ||
                            (selectedTestType &&
                              selectedTestType == 'written-test')
                          "
                          class="px-2 py-2"
                          :class="{
                            'bg-a-grading':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) == 'a',
                            'bg-b-grading':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) == 'b',
                            'bg-c-grading':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) == 'c',
                            'bg-delta-grading':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) == 'delta',
                            'bg-fail-grading':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) == 'fail',
                            'text-white':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) == 'fail',
                            'text-black':
                              getColorVariantForPercentage(
                                getTotalOfWrittenTests,
                                getTotalObtainedMarks(student, 'written-test')
                              ) != 'fail',
                          }"
                          pill
                        >
                          {{
                            getColorVariantForPercentage(
                              getTotalOfWrittenTests,
                              getTotalObtainedMarks(student, "written-test")
                            ).toUpperCase()
                          }}
                        </b-badge>
                        <b-badge
                          v-if="
                            !selectedTestType ||
                            (selectedTestType &&
                              selectedTestType == 'practical-test')
                          "
                          class="mx-1 px-2 py-2"
                          :class="{
                            'bg-a-grading':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) == 'a',
                            'bg-b-grading':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) == 'b',
                            'bg-c-grading':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) == 'c',
                            'bg-delta-grading':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) == 'delta',
                            'bg-fail-grading':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) == 'fail',
                            'text-white':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) == 'fail',
                            'text-black':
                              getColorVariantForPercentage(
                                getTotalOfPracticalTests,
                                getTotalObtainedMarks(student, 'practical-test')
                              ) != 'fail',
                          }"
                          pill
                        >
                          {{
                            getColorVariantForPercentage(
                              getTotalOfPracticalTests,
                              getTotalObtainedMarks(student, "practical-test"),
                              "practical-test"
                            ).toUpperCase()
                          }}
                        </b-badge>
                      </template>
                    </td>
                  </tr>
                </table>
              </template>

              <table
                v-if="selectedCourse && selectedViewType == 'students'"
                class="table table-bordered border bg-white border-rounded w-100 rounded my-3"
              >
                <tr>
                  <th class="col-student-irla-index"></th>
                  <template v-for="field in studentFields">
                    <th :key="`student-view-${field.id}`">
                      {{ field.title }}
                    </th>
                  </template>
                </tr>
                <tr
                  v-for="(student, index) in localStudentsList"
                  :key="`student-view-${student.key}`"
                >
                  <td
                    :key="`student-view-${student.key}`"
                    class="col-student-irla-index"
                  >
                    {{ index + 1 }}
                  </td>
                  <template v-for="field in studentFields">
                    <td :key="`student-view-${student.key}-${field.id}`">
                      {{ student[field.id] }}
                    </td>
                  </template>
                  <!-- <td>
                    <button class="btn btn-sm text-primary">
                      Edit <i class="fa fa-xs fa-pencil pl-2 text-primary"></i>
                    </button>
                    <button
                      class="btn btn-sm text-danger action-buttons"
                      @click="openDeleteStudentConfirmationModal(student)"
                    >
                      Delete
                    </button>
                  </td> -->
                  <!-- <ConfirmationModal
                    :ref="`delete-student-${student.key}-modal`"
                    :id="student.key"
                    :title="'Remove Student'"
                    :content="'Are you sure you want to remove this student?'"
                    :cancelButtonText="'Cancel'"
                    :okButtonText="'Delete'"
                    @onOk="deleteStudentDetails(student)"
                    @onCancel="hideDeleteConfirmation(student)"
                  /> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BTable,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BFormInput,
  BButton,
  BBadge,
} from "bootstrap-vue";
import { HotTable } from "@handsontable/vue";
import { mapActions, mapGetters, mapState } from "vuex";
import AddStudent from "@/components/modal/AddStudent.vue";
import ConfirmationModal from "@/components/modal/ConfirmationModal.vue";
// import AddCourse from "@/components/modal/AddCourse.vue";

export default {
  name: "ResultsPage",
  components: {
    BTable,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BFormInput,
    BButton,
    BBadge,
    HotTable,
    AddStudent,
    ConfirmationModal,
    // AddCourse,
  },
  data() {
    return {
      selectedCourse: null,
      selectedWing: null,
      wings: ["PW", "WT", "AT"],
      selectedStudent: null,
      selectedGrading: null,
      gradings: [
        {
          type: "practical-test",
          id: "d-grading-practical",
          title: "D",
          percentage: 0.8,
        },
        {
          type: "practical-test",
          id: "x-grading",
          title: "X",
          percentage: 0.7,
        },
        {
          type: "practical-test",
          id: "y-grading",
          title: "Y",
          percentage: 0.6,
        },
        {
          type: "practical-test",
          id: "z-grading",
          title: "Z",
          percentage: 0.45,
        },
        {
          type: "written-test",
          id: "d-grading-written",
          title: "D",
          percentage: 0.8,
        },
        {
          type: "written-test",
          id: "a-grading",
          title: "A",
          percentage: 0.7,
        },
        {
          type: "written-test",
          id: "b-grading",
          title: "B",
          percentage: 0.6,
        },
        {
          type: "written-test",
          id: "c-grading",
          title: "C",
          percentage: 0.45,
        },
      ],
      testTypes: [
        {
          id: "written-test",
          title: "Written",
        },
        {
          id: "practical-test",
          title: "Practical",
        },
      ],
      viewTypes: [
        {
          id: "students",
          title: "Students List",
        },
        {
          id: "results",
          title: "Results",
        },
      ],
      selectedViewType: null,
      selectedTestType: null,
      writtenTestsList: [],
      selectedTest: null,
      finalFields: [],
      toggleEditMarks: {},
      localStudents: [],
      studentFields: [
        {
          id: "IRLA/Regimental Number",
          title: "IRLA",
        },
        {
          id: "name",
          title: "Name",
        },
        {
          id: "Battalion",
          title: "Unit",
        },
        {
          id: "phone",
          title: "Phone",
        },
        {
          id: "Rank",
          title: "Rank",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userStatus: (state) => state.userStatus,
      status: (state) => state.status,
      students: (state) => state.students,
      studentsStatus: (state) => state.studentsStatus,
      studentsError: (state) => state.studentsError,
      courses: (state) => state.courses,
      tests: (state) => state.tests,
      currentCourse: (state) => state.currentCourse,
      studentForCalculation: (state) => state.studentForCalculation,
    }),
    ...mapGetters(["isUserGod", "isUserAdmin", "isUserOffice"]),
    getCourseTestsList() {
      return this.tests.filter(
        (t) => t.course && t.course.id === this.selectedCourse
      );
    },
    localStudentsList: {
      get() {
        if (!this.isUserOffice) {
          return this.localStudents.filter(
            (student) => student.key == this.user.key
          );
        }
        return this.localStudents.sort(this.compareNumbers);
      },
      set(val) {
        this.localStudents = Array.from(val);
      },
    },
    getGradingsList() {
      if (this.selectedTestType) {
        return this.gradings.filter(
          (grading) => grading.type === this.selectedTestType
        );
      }
      return this.gradings;
    },
    getTestsLists() {
      // console.log("getTestsLists", this.selectedTest);

      if (this.selectedTest) {
        let selectedTestDetails = this.getCourseTestsList.find(
          (test) => test.id === this.selectedTest
        );
        // console.log("selectedTestDetails", selectedTestDetails);
        if (selectedTestDetails && selectedTestDetails.subtests_available) {
          return selectedTestDetails.subtests;
        } else {
          return this.getCourseTestsList.filter(
            (test) => test.id === this.selectedTest
          );
        }
      } else if (this.selectedTestType) {
        return this.getCourseTestsList.filter(
          (test) => test.type == this.selectedTestType
        );
      } else {
        let writtenTests = this.getCourseTestsList.filter(
          (t) => t.type == "written-test"
        );
        let practicalTests = this.getCourseTestsList.filter(
          (t) => t.type == "practical-test"
        );
        return [...writtenTests, ...practicalTests];
      }
    },
    getWrittenTestsList() {
      if (this.selectedTest && this.selectedTestType == "written-test") {
        let selectedTestDetails = this.getCourseTestsList.find(
          (test) => test.id === this.selectedTest
        );
        if (selectedTestDetails && selectedTestDetails.subtests_available) {
          return selectedTestDetails.subtests;
        } else {
          return this.getCourseTestsList.filter(
            (test) => test.id === this.selectedTest
          );
        }
      } else if (this.selectedTestType == "practical-test") {
        return [];
      } else {
        let writtenTests = this.getCourseTestsList.filter(
          (t) => t.type == "written-test"
        );
        return writtenTests;
      }
    },
    getPracticalTestsList() {
      if (this.selectedTest && this.selectedTestType == "practical-test") {
        let selectedTestDetails = this.getCourseTestsList.find(
          (test) => test.id === this.selectedTest
        );
        if (selectedTestDetails && selectedTestDetails.subtests_available) {
          return selectedTestDetails.subtests;
        } else {
          return this.getCourseTestsList.filter(
            (test) => test.id === this.selectedTest
          );
        }
      } else if (this.selectedTestType == "written-test") {
        return [];
      } else {
        let practicalTests = this.getCourseTestsList.filter(
          (t) => t.type == "practical-test"
        );
        return practicalTests;
      }
    },
    showWrittenTests() {
      return (
        !this.selectedTestType ||
        (this.selectedTestType && this.selectedTestType == "written-test")
      );
    },
    showPracticalTests() {
      return (
        !this.selectedTestType ||
        (this.selectedTestType && this.selectedTestType == "practical-test")
      );
    },
    getTotalOfWrittenTests() {
      let total = 0;
      for (let test of this.getWrittenTestsList) {
        total += test.weightage_marks;
      }
      return total;
    },
    getTotalOfPracticalTests() {
      let total = 0;
      for (let test of this.getPracticalTestsList) {
        total += test.weightage_marks;
      }
      return total;
    },
    getFinalTestsList() {
      return this.getCourseTestsList.filter(
        (test) => test.type == this.selectedTestType
      );
    },
    getCurrentGradingWritten() {
      let percentage =
        (this.getTotalObtainedMarksWrittenCurrent /
          this.getTotalMarksOfAllWrittenTests) *
        100;
      if (percentage >= 80) {
        return "D";
      } else if (percentage >= 70) {
        return "A";
      } else if (percentage < 70 && percentage >= 60) {
        return "B";
      } else if (percentage < 60 && percentage >= 45) {
        return "C";
      } else {
        return "Fail";
      }
    },
    getCurrentGradingPractical() {
      let percentage =
        (this.getTotalObtainedMarksPracticalCurrent /
          this.getTotalMarksOfAllPracticalTests) *
        100;
      if (percentage >= 80) {
        return "D";
      } else if (percentage >= 70) {
        return "X";
      } else if (percentage < 70 && percentage >= 60) {
        return "Y";
      } else if (percentage < 60 && percentage >= 45) {
        return "Z";
      } else {
        return "Fail";
      }
    },
    getTotalMarksOfAllWrittenTests() {
      let total = 0;
      for (let test of this.getCourseTestsList.filter(
        (t) => t.type === "written-test"
      )) {
        total += test.weightage_marks;
      }
      return total;
    },
    getTotalMarksOfAllPracticalTests() {
      let total = 0;
      for (let test of this.getCourseTestsList.filter(
        (t) => t.type === "practical-test"
      )) {
        total += test.weightage_marks;
      }
      return total;
    },
    getTotalObtainedMarksWrittenCurrent() {
      let testsList = this.getCourseTestsList.filter(
        (t) => t.type === "written-test"
      );
      let totalObtainedMarks = 0;
      for (let test of testsList) {
        let foundTest = this.user.tests.find((t) => t.id == test.id);
        if (foundTest) {
          totalObtainedMarks += foundTest.obtained_marks;
        }
      }
      return Number(Number.parseFloat(totalObtainedMarks).toFixed(2));
    },
    getTotalObtainedMarksPracticalCurrent() {
      let testsList = this.getCourseTestsList.filter(
        (t) => t.type === "practical-test"
      );
      let totalObtainedMarks = 0;
      for (let test of testsList) {
        let foundTest = this.user.tests.find((t) => t.id == test.id);
        if (foundTest) {
          totalObtainedMarks += foundTest.obtained_marks;
        }
      }
      return Number(Number.parseFloat(totalObtainedMarks).toFixed(2));
    },
  },
  beforeMount() {
    if (this.isUserGod) {
      this.getCourses();
    } else if (this.isUserAdmin || this.isUserOffice) {
      this.getCourses({ wing: this.user.wing });
    }
  },
  mounted() {
    if (!this.isUserOffice) {
      this.selectedCourse = this.user ? this.user.course.id : null;
      this.selectedViewType == "results";
    }
  },
  beforeDestroy: function () {
    if (this.isUserOffice) {
      this.selectedCourse = null;
    }
  },
  methods: {
    ...mapActions([
      "getTests",
      "getCourses",
      "getStudents",
      "editUser",
      "deleteUser",
      "updateCurrentCourse",
      "getStudent",
      "logActivityToHistory",
    ]),
    getPercentage(totalMarks, obtainedMarks) {
      return (obtainedMarks / totalMarks) * 100;
    },
    getColorVariantForPercentage(total, obtained, type) {
      let percentage = (obtained / total) * 100;

      if (percentage >= 80) {
        return "delta";
      } else if (percentage >= 70) {
        return type == "practical-test" ? "x" : "a";
      } else if (percentage < 70 && percentage >= 60) {
        return type == "practical-test" ? "y" : "b";
      } else if (percentage < 60 && percentage >= 45) {
        return type == "practical-test" ? "z" : "c";
      } else {
        return "fail";
      }
    },
    getCalculatedShortMarks(total_marks, obtained_marks) {
      // Grading based logic fro marks that are short
      let selectedGradingDetails = this.gradings.find(
        (grading) => grading.id == this.selectedGrading
      );
      return Number.parseFloat(
        obtained_marks - selectedGradingDetails.percentage * total_marks
      ).toFixed(2);
    },
    editUserObtainedMarks(student, test) {
      // console.log("editUserObtainedMarks(student, test)", student, test);
      this.$set(
        this.toggleEditMarks,
        `student-${student.key}-test-${test.id}-edit`,
        true
      );

      this.$refs[
        `student-${student.key}-test-${test.id}-1`
      ][0].$refs.input.style.width = "10px";
      this.$nextTick(() => {
        this.$refs[
          `student-${student.key}-test-${test.id}-1`
        ][0].$refs.input.focus();
      });
    },
    async onEditMarks(student, test) {
      // console.log("onEditMarks", student, test, this.selectedTest);
      let previousMarks = null;
      let currentMarks = null;
      let previousTestResult = null;
      let currentTestResult = null;
      let calculatedTest = this.setTestMarksBasedOnSubtests(
        JSON.parse(JSON.stringify(student)),
        test,
        this.selectedTest
      );
      let updatedTestState = null;
      // console.log("onEditMarks calculatedTest", calculatedTest);
      await this.getStudent(student.key);

      // console.log("onEditMarks fetchedStudent", this.studentForCalculation);
      let foundTestIndex = this.studentForCalculation.tests
        ? this.studentForCalculation.tests.findIndex(
            (t) => t.id == this.selectedTest
          )
        : null;
      // console.log(
      //   "onEditMarks foundTestIndex",
      //   foundTestIndex,
      //   this.studentForCalculation
      // );
      if (foundTestIndex > -1) {
        previousMarks =
          this.studentForCalculation.tests[foundTestIndex].obtained_marks;
        previousTestResult = JSON.parse(
          JSON.stringify(this.studentForCalculation.tests[foundTestIndex])
        );
        currentMarks = calculatedTest.obtained_marks;
        currentTestResult = JSON.parse(JSON.stringify(calculatedTest));
        updatedTestState = this.getUpdatedTestState(
          JSON.parse(
            JSON.stringify(this.studentForCalculation.tests[foundTestIndex])
          ),
          JSON.parse(JSON.stringify(calculatedTest))
        );
        console.log("updatedTestState", updatedTestState);
        this.studentForCalculation.tests[foundTestIndex] = {
          ...calculatedTest,
        };
      } else if (foundTestIndex === -1) {
        let testDetails = { ...test };
        if (testDetails.subtests_available) {
          for (let i = 0; i < testDetails.subtests.length; i++) {
            // testDetails.subtests[i].obtained_marks = 0;
            calculatedTest.subtests[i].obtained_marks = 0;
          }
        }
        // this.studentForCalculation.tests.push({
        //   ...testDetails,
        //   obtained_marks: 0,
        // });
        this.studentForCalculation.tests.push({
          ...calculatedTest,
          obtained_marks: calculatedTest.obtained_marks,
        });
      } else {
        this.studentForCalculation.tests = [];
      }
      await this.editUser({
        ...this.studentForCalculation,
      });

      this.toggleEditMarks[
        `student-${student.key}-test-${test.id}-edit`
      ] = false;
      console.log("selectedTest", this.selectedTest, updatedTestState);
      if (updatedTestState) {
        this.logActivityToHistory({
          student: JSON.parse(JSON.stringify(this.studentForCalculation)),
          modifiedBy: this.user,
          test: { ...updatedTestState },
          previousMarks: previousMarks,
          currentMarks: currentMarks,
          previousTestState: previousTestResult,
          currentTestState: currentTestResult,
          modifiedAt: Date.now(),
        });
      } else {
        console.log("Test updated for the first time!!");
      }
    },
    getUpdatedTestState(previous, current) {
      console.log("getUpdatedTestState", previous, current);
      let updatedState = {
        id: current.id,
        title: current.title,
        subtests_available: current.subtests_available,
        previousMarks: previous.obtained_marks,
        currentMarks: current.obtained_marks,
      };
      updatedState.subtests = [];
      if (current.subtests_available) {
        for (let i = 0; i < current.subtests.length; i++) {
          if (
            current.subtests[i].obtained_marks !==
              previous.subtests[i].obtained_marks &&
            previous.subtests[i].obtained_marks !== 0
          ) {
            let subtestDiff = {
              id: current.subtests[i].id,
              title: current.subtests[i].title,

              previousMarks: previous.subtests[i].obtained_marks,
              currentMarks: current.subtests[i].obtained_marks,
            };
            updatedState.subtests.push(subtestDiff);
          }
        }
        if (updatedState.subtests.length) {
          return updatedState;
        } else return null;
      } else {
        if (
          current.obtained_marks !== previous.obtained_marks &&
          previous.obtained_marks !== 0
        ) {
          return updatedState;
        } else return null;
      }
    },
    checkIfSubtestUpdated(previous, current) {
      console.log(previous, current);
      return true;
    },
    onCancelEdit(student, test) {
      this.toggleEditMarks[
        `student-${student.key}-test-${test.id}-edit`
      ] = false;
      this.localStudentsList = JSON.parse(JSON.stringify(this.students));
    },
    getTestMarks(student, test) {
      // console.log("getTestMarks student", student, test, this.selectedTest);
      if (this.selectedTest) {
        let selectedTestDetails = student.tests.find(
          (test) => test.id === this.selectedTest
        );
        // console.log("selectedTestDetails", selectedTestDetails);
        if (selectedTestDetails && selectedTestDetails.subtests_available) {
          let currentSubtestIndex = selectedTestDetails.subtests.findIndex(
            (subtest) => subtest.id == test.id
          );
          if (currentSubtestIndex > -1) {
            return selectedTestDetails.subtests[currentSubtestIndex];
          }
        } else {
          if (!selectedTestDetails) {
            let testDetails = { ...test, obtained_marks: 0 };
            if (testDetails.subtests_available) {
              for (let i = 0; i < testDetails.subtests.length; i++) {
                testDetails.subtests[i].obtained_marks = 0;
              }
            }
            return testDetails;
          } else return selectedTestDetails;
        }
      } else {
        let currentTestIndex = student.tests.findIndex((t) => t.id == test.id);
        if (currentTestIndex > -1) {
          return student.tests[currentTestIndex];
        } else {
          let testDetails = { ...test, obtained_marks: 0 };
          if (testDetails.subtests_available) {
            for (let i = 0; i < testDetails.subtests.length; i++) {
              testDetails.subtests[i].obtained_marks = 0;
            }
          }
          return testDetails;
        }
      }
    },
    getTotalObtainedMarks(student, type) {
      let testsList = [];
      if (type == "written-test") {
        testsList = this.getWrittenTestsList;
      } else if (type == "practical-test") {
        testsList = this.getPracticalTestsList;
      } else {
        testsList = [
          ...this.getWrittenTestsList,
          ...this.getPracticalTestsList,
        ];
      }

      let totalObtainedMarks = 0;
      for (let test of testsList) {
        totalObtainedMarks += this.getTestMarks(student, test).obtained_marks;
      }
      return Number(Number.parseFloat(totalObtainedMarks).toFixed(2));
    },
    getTotalShortMarks(student, type) {
      // console.log("getTotalShortMarks", student, type);
      let selectedGradingDetails = this.gradings.find(
        (grading) => grading.id == this.selectedGrading
      );
      let total_marks =
        type == "written-test"
          ? this.getTotalOfWrittenTests
          : this.getTotalOfPracticalTests;
      return Number.parseFloat(
        this.getTotalObtainedMarks(student, type) -
          selectedGradingDetails.percentage * total_marks
      ).toFixed(2);
    },
    getSubtestsForTest(test) {
      // console.log("getSubtestsForTest", test);
      if (test.subtests_available) {
        // this.selectedCourse = this.selectedCourse;
        this.selectedTestType = test.type;
        this.selectedTest = test.id;
      }
    },
    setTestMarksBasedOnSubtests(student, subtest) {
      // console.log(
      //   "setTestMarksBasedOnSubtests onEditMarks",
      //   student,
      //   subtest,
      //   this.selectedTest
      // );
      // let currentStudent = null;
      // if (
      //   student.tests &&
      //   student.tests.length !==
      //     this.tests.filter((t) => t.course.id == student.course.id).length
      // ) {
      //   currentStudent = await this.getStudent(student.key);
      // } else {
      //   currentStudent = { ...student };
      // }
      let testIndex = student.tests.findIndex(
        (t) => t.id === this.selectedTest
      );
      // console.log("testtesttest", testIndex);
      if (testIndex > -1) {
        let currentTest = student.tests[testIndex];

        let total = currentTest.total_marks;
        let weightage = currentTest.weightage_marks;
        // console.log("testtesttest total, weightage", total, weightage);
        let currentSubtestsTotal = 0;
        if (currentTest.subtests_available) {
          for (let i = 0; i < currentTest.subtests.length; i++) {
            currentSubtestsTotal += currentTest.subtests[i].obtained_marks;
          }
        } else {
          currentSubtestsTotal = currentTest.obtained_marks;
        }
        let finalObtainedMarks = (currentSubtestsTotal * weightage) / total;
        currentTest.obtained_marks = Number(
          Number.parseFloat(finalObtainedMarks).toFixed(2)
        );
        return currentTest;
      } else {
        let currentTest = {
          ...this.tests.find((t) => t.id == this.selectedTest),
        };
        currentTest.obtained_marks = 0;
        let total = currentTest.total_marks;
        let weightage = currentTest.weightage_marks;
        // console.log("testtesttest total, weightage else", total, weightage);
        let currentSubtestsTotal = 0;
        if (currentTest.subtests_available) {
          for (let i = 0; i < currentTest.subtests.length; i++) {
            currentSubtestsTotal = 0;
          }
        } else {
          currentSubtestsTotal = 0;
        }
        let finalObtainedMarks = (currentSubtestsTotal * weightage) / total;
        currentTest.obtained_marks = Number(
          Number.parseFloat(finalObtainedMarks).toFixed(2)
        );
        return currentTest;
      }
    },
    hideAddStudentModal() {
      this.$refs.addStudentModal.$refs.addStudentModal.hide();
    },
    openAddStudentModal() {
      this.$refs.addStudentModal.$refs.addStudentModal.show();
    },
    deleteStudentDetails(student) {
      this.deleteUser(student);
    },
    hideDeleteConfirmation(student) {
      this.$refs[`delete-student-${student.key}-modal`][0].$refs[
        `confirmation-modal-${student.key}`
      ].hide();
    },
    openDeleteStudentConfirmationModal(student) {
      this.$refs[`delete-student-${student.key}-modal`][0].$refs[
        `confirmation-modal-${student.key}`
      ].show();
    },
    // hideAddCourseModal() {
    //   this.$refs.addCourseModal.$refs.addCourseModal.hide();
    // },
    // openAddCourseModal() {
    //   this.$refs.addCourseModal.$refs.addCourseModal.show();
    // },
    onEditByEnterOrTab(event, student, test) {
      // console.log("onEditByEnterOrTab", event);
      this.onEditMarks(student, this.getTestMarks(student, test));
      // event.keyCode = 9;
    },
    openTestsPage() {
      this.$router.push("/tests");
    },
    navigateToUsers() {
      this.$router.push("/users");
    },
    compareNumbers(a, b) {
      //console.log('a.Battalion: ' + a.Battalion);
      //console.log('b.Battalion: ' + b.Battalion);
      //console.log('-------');
      if (typeof a.Battalion === "number" && typeof b.Battalion === "number") {
        return a.Battalion - b.Battalion;
      }
      // check for num vs string
      if (typeof a.Battalion === "number" && typeof b.Battalion === "string") {
        return -1;
      }
      // check for string vs num
      if (typeof a.Battalion === "string" && typeof b.Battalion === "number") {
        return 1;
      }
      // check for string vs string
      if (typeof a.Battalion === "string" && typeof b.Battalion === "string") {
        if (a.Battalion < b.Battalion) return -1;
        else return 1;
      }
      return 0;
    },
  },
  watch: {
    students(val) {
      if (this.selectedCourse) {
        this.localStudentsList = JSON.parse(JSON.stringify(val));
      } else {
        this.localStudentsList = [];
      }
    },
    selectedTest(val) {
      if (!this.isUserOffice) {
        this.getStudents({ course: this.user.course, isCourseSelected: true });
        this.localStudentsList = JSON.parse(JSON.stringify(this.students));
      } else if (!val) {
        if (this.selectedCourse) {
          let courseDetails = this.courses.find(
            (course) => course.id == this.selectedCourse
          );
          this.getStudents({ course: courseDetails, isCourseSelected: true });
          this.localStudentsList = JSON.parse(JSON.stringify(this.students));
        } else {
          if (this.isUserGod) {
            this.getStudents({ isCourseSelected: false });
            this.localStudentsList = JSON.parse(JSON.stringify(this.students));
          } else if (this.isUserAdmin || this.isUserOffice) {
            this.getStudents({ isCourseSelected: false, wing: this.user.wing });
            this.localStudentsList = JSON.parse(JSON.stringify(this.students));
          }
        }
      }
    },
    selectedTestType: {
      handler(val) {
        this.selectedTest = null;
        if (!val) {
          this.selectedTest = null;
        }
        if (val && val === "written-test") {
          this.selectedGrading = "a-grading";
        } else if (val && val === "practical-test") {
          this.selectedGrading = "x-grading";
        } else this.selectedGrading = null;

        if (!this.isUserOffice) {
          this.getStudents({
            course: this.user.course,
            isCourseSelected: true,
          });
          this.localStudentsList = JSON.parse(JSON.stringify(this.students));
        } else if (!val) {
          if (this.selectedCourse) {
            let courseDetails = this.courses.find(
              (course) => course.id == this.selectedCourse
            );
            this.getStudents({ course: courseDetails, isCourseSelected: true });
            this.localStudentsList = JSON.parse(JSON.stringify(this.students));
          } else {
            if (this.isUserGod) {
              this.getStudents({ isCourseSelected: false });
              this.localStudentsList = JSON.parse(
                JSON.stringify(this.students)
              );
            } else if (this.isUserAdmin || this.isUserOffice) {
              this.getStudents({
                isCourseSelected: false,
                wing: this.user.wing,
              });
              this.localStudentsList = JSON.parse(
                JSON.stringify(this.students)
              );
            }
          }
        }
      },
    },
    selectedViewType(val) {
      this.selectedTestType = null;
      this.selectedTest = null;
    },
    user: {
      handler(val) {
        // console.log("user watcher", val);
        if (this.isUserOffice) {
          this.selectedCourse = null;
          this.selectedViewType = null;
        } else if (val && val.course) {
          this.selectedCourse = val.course.id;
          if (val) {
            this.getStudents({ course: val.course, isCourseSelected: true });
            this.getTests({ course: val.course, wing: val.wing });
          }
          this.selectedViewType = "results";
        }
      },
    },
    selectedCourse(val) {
      if (!this.isUserOffice) {
        this.updateCurrentCourse(this.user.course);
        this.selectedViewType = "results";
        this.getStudents({ course: this.user.course, isCourseSelected: true });
        this.getTests({ course: this.user.course, wing: this.user.wing });
      } else if (this.isUserOffice && val) {
        let courseDetails = this.courses.find((course) => course.id == val);
        this.updateCurrentCourse(courseDetails);
        this.selectedViewType = "results";
        this.getStudents({ course: courseDetails, isCourseSelected: true });
        this.getTests({ course: courseDetails, wing: this.user.wing });
      } else {
        this.updateCurrentCourse(null);
        this.selectedViewType = null;
      }
      this.localStudentsList = JSON.parse(JSON.stringify(this.students));
    },
    selectedWing(val) {
      if (val) {
        this.getCourses({ wing: this.selectedWing });
      } else {
        this.getCourses();
      }
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow-x: auto;
}
.table {
  overflow: auto;
  width: auto;
  padding: 0 !important;
}
td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center !important;
}
td:not(
    .col-student-irla-index,
    .col-user-test-index,
    .col-student-irla,
    .short-marks-column
  ) {
  min-width: 110px;
}
.short-marks-column {
  width: 30px !important;
}
th {
  /* min-width: 80px; */
  font-weight: 500;
  padding: 8px;
  text-align: center !important;
  /* border: 1px solid lightgrey; */
}
.second-heading th {
  font-size: 14px !important;
}
.user-result-table td:not(.col-user-test-index) {
  min-width: 70px;
}
#results-course-header {
  top: 0;
  position: sticky;
  z-index: 20;
}
.test-heading-bg-written {
  background: #fce5cd;
  color: black;
}
.test-heading-bg-practical {
  background: cornflowerblue;
  color: white;
}
.col-student-irla-index {
  width: 20px !important;
  left: -1px;
  position: sticky;
  background-color: white;
}
.col-student-irla {
  left: 25px;
  position: sticky;
  background-color: white;
}
.col-student-name {
  left: 115px;
  position: sticky;
  background-color: white;
}
.col-user-test-index {
  width: 20px !important;
  left: -1px;
  position: sticky;
  background-color: white;
}
.col-user-test-name {
  left: 37px;
  position: sticky;
  background-color: white;
}
.col-user-test-name-total,
.col-user-test-name-heading,
.col-user-test-name-heading-index {
  background-color: #eaebed !important;
  font-weight: 600;
}
.col-user-test-name-type {
  font-weight: 600;
}
.table-dummy-row-column {
  height: 37px !important;
  padding: 10px;
}
.table-heading-bg {
  background-color: #eaebed;
}
.input-group-append {
  position: absolute;
  right: 5px !important;
  cursor: pointer !important;
}
.badge-filters {
  margin-right: 0.5rem;
}
.edit-button {
  position: absolute;
  right: 24px;
  top: 5px;
  z-index: 20;
}
.cancel-button {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 20;
  font-size: 14px;
}
.marks-input {
  padding-top: 0;
  padding-bottom: 0;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.second-heading {
  color: darkslategrey;
  font-size: 12px;
}
/* .w-20 {
  width: 20% !important;
} */
.user-name {
  font-size: 16px !important;
  font-weight: 600 !important;
}
#content {
  height: 37px !important;
  padding: 10px;
  overflow: hidden;
}
.margin-left-2 {
  margin-left: 1rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style>
.form-group label {
  font-size: 16px !important;
  font-weight: 500 !important;
}
</style>