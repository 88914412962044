<template>
  <div id="app">
    <div class="app-container">
      <NavBar />
      <div class="container my-4">
        <router-view></router-view>
      </div>
    </div>

    <Footer class="app-footer" />
  </div>
</template>

<script>
import NavBar from "@/components/layouts/NavBar.vue";
import Footer from "@/components/layouts/Footer.vue";
export default {
  name: "App",
  components: { NavBar, Footer },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: ghostwhite;
}
</style>
<style scoped>
.app-container {
  min-height: 83vh;
  display: flex;
  flex-direction: column;
}
.app-footer {
  margin-top: auto;
}
</style>