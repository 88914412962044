<template>
  <div>
    <div class="border rounded bg-white">
      <b-table
        borderless
        hover
        primary-key="index"
        :items="test.subtests"
        :fields="fields"
        class="mb-0"
      >
      </b-table>
    </div>
  </div>
</template>
<script>
import { BTable, BButton } from "bootstrap-vue";

export default {
  name: "SubtestsList",
  props: {
    test: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        { key: "title", label: "Title", sortDirection: "asc" },
        {
          key: "total_marks",
          label: "Total",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    editSubtest(subtest) {
      console.log("editSubtest", subtest, this.test);
    },
  },
};
</script>
<style>
thead {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
</style>