<template>
  <div>
    <nav
      class="navbar navbar-dark bg-dark justify-content-between flex-nowrap flex-row bg-brand"
    >
      <div class="container d-flex justify-content-between flex-wrap">
        <router-link
          class="navbar-brand nav-link pr-3 float-left align-items-center"
          to="/"
        >
          <img class="brand-logo" src="../../../public/BSF_LOGO.png" />
          <span class="webapp-heading px-2">CSWT Student Portal</span>
          <span class="webapp-heading-mobile display-none px-2"
            >CSWT</span
          ></router-link
        >
        <ul class="nav navbar-nav flex-row float-right align-items-center">
          <li v-if="userStatus === 'loading'">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </li>
          <template v-else>
            <li
              v-if="isUserOffice && user"
              class="nav-item"
              :class="isTabHistory ? 'tab-selected' : ''"
            >
              <router-link class="nav-link px-2" to="/history"
                >History</router-link
              >
            </li>
            <li
              v-if="isUserOffice && user"
              class="nav-item"
              :class="isTabCourses ? 'tab-selected' : ''"
            >
              <router-link class="nav-link px-2" to="/courses"
                >Courses</router-link
              >
            </li>
            <li
              v-if="isUserOffice && user"
              class="nav-item"
              :class="isTabUsers ? 'tab-selected' : ''"
            >
              <router-link class="nav-link px-2" to="/users">Users</router-link>
            </li>

            <li
              v-if="isUserOffice && user"
              class="nav-item"
              :class="isTabTests ? 'tab-selected' : ''"
            >
              <router-link class="nav-link px-2" to="/tests">Tests</router-link>
            </li>

            <li
              v-if="user"
              class="nav-item"
              :class="isTabResults ? 'tab-selected' : ''"
            >
              <router-link class="nav-link px-2" to="/results"
                >Results</router-link
              >
            </li>
            <!-- <li
              class="nav-item"
              v-show="user"
              :class="isTabNotices ? 'tab-selected' : ''"
            >
              <router-link class="nav-link px-2" to="/notices"
                >Notices</router-link
              >
            </li> -->
          </template>

          <li class="nav-item">
            <b-dropdown
              text="text left"
              variant="transparent"
              class="user-profile-avatar p-0"
              no-caret
            >
              <template #button-content>
                <b-avatar
                  v-if="user && user.image"
                  class="border ml-3 p-0"
                  variant="info"
                  :src="user.image"
                  size="2rem"
                ></b-avatar>
                <b-avatar
                  v-else-if="user"
                  class="border ml-3 user-profile-avatar-icon p-0"
                  :text="getUserInitials"
                  size="2rem"
                ></b-avatar>
                <b-avatar
                  v-else
                  class="border ml-3 glyphicon glyphicon-user cursor-pointer user-profile-avatar-icon p-0"
                  size="2rem"
                  @click="goToUserLogin"
                ></b-avatar>
              </template>
              <b-dropdown-item v-if="user" href="#" @click="goToUserProfile"
                >Profile</b-dropdown-item
              >
              <div v-if="user" class="dropdown-divider"></div>
              <b-dropdown-item v-if="!user" href="#" @click="goToUserLogin">
                Login
              </b-dropdown-item>
              <b-dropdown-item v-else href="#" @click="logoutUser">
                Logout
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
import { BAvatar, BDropdown, BDropdownItem } from "bootstrap-vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "NavBar",
  components: {
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userStatus: (state) => state.userStatus,
      userError: (state) => state.userError,
    }),
    ...mapGetters(["isUserOffice"]),
    getUserInitials() {
      let name = this.user ? this.user.name : "";
      if (name) {
        let namesSplit = name.split(" ");
        return namesSplit.length > 1
          ? namesSplit[0][0].toUpperCase() + namesSplit[1][0].toUpperCase()
          : namesSplit[0].substring(0, 2).toUpperCase();
      } else return this.user ? this.user.email.slice(0, 1).toUpperCase() : "";
    },
    isTabCourses() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "courses"
      );
    },
    isTabTests() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "tests"
      );
    },
    isTabUsers() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "users"
      );
    },
    isTabHistory() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "history"
      );
    },
    isTabResults() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "results"
      );
    },
    isTabNotices() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "notices"
      );
    },
    isTabUsers() {
      return (
        this.$route &&
        this.$route.fullPath.split("/").length > 1 &&
        this.$route.fullPath.split("/")[1] == "users"
      );
    },
  },
  methods: {
    ...mapActions(["logoutAction"]),
    goToUserLogin() {
      this.$router.push("/login");
    },
    goToUserProfile() {
      this.$router.push("/profile");
    },
    logoutUser() {
      this.logoutAction();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.ml-3 {
  margin-left: 1rem;
}

.user-profile-avatar-icon {
  color: white;
}
*:focus {
  outline: none;
}
.tab-selected {
  border-bottom: 2px solid white;
  font-weight: 700;
}
.tab-selected a {
  color: white;
}
@media screen and (max-width: 767px) {
  .webapp-heading {
    display: none;
  }
  .webapp-heading-mobile {
    display: inline;
  }
}
@media screen and (min-width: 1024px) {
  .webapp-heading {
    display: inline;
  }
  .webapp-heading-mobile {
    display: none;
  }
}
</style>