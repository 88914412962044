<template>
  <b-modal
    :ref="`edit-user-phone-modal-${student.key}`"
    size="lg"
    v-bind="$attrs"
    button-size="sm"
    hide-footer
  >
    <div id="update-user-phone-container">
      <b-form class="container justify-content-center w-auto">
        <b-form-group
          id="user-phone-id"
          class="pb-3 col-5"
          label="Phone Number"
          label-for="user-phone"
        >
          <b-form-input
            class="form-control form-control-sm"
            id="user-phone"
            v-model.number="phone"
            type="number"
            placeholder="Add user's phone number"
          ></b-form-input>
        </b-form-group>

        <b-button class="btn btn-sm px-3 bg-brand" @click.prevent="updatePhone">
          Link Phone Number
          <i
            v-if="updateUserPhoneNumberStatus === 'loading'"
            class="fa-solid fa-spinner fa-spin mx-2"
          ></i>
        </b-button>
        <b-button class="btn btn-sm px-3 mx-2" type="reset" @click="onReset"
          >Reset</b-button
        >
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm, BModal } from "bootstrap-vue";
export default {
  name: "EditUserPhoneNumberModal",
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
  },
  data() {
    return {
      phone: this.student.phone,
    };
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      updateUserPhoneNumberStatus: (state) => state.updateUserPhoneNumberStatus,
      updateUserPhoneNumberError: (state) => state.updateUserPhoneNumberError,
    }),
  },
  methods: {
    ...mapActions(["updateUserPhoneNumber"]),
    async updatePhone() {
      console.log("updateUserPhoneNumber");
      let phoneNumberWithCountryCode = this.phone ? `+91${this.phone}` : null;
      await this.updateUserPhoneNumber({
        uid: this.student.key,
        phone: phoneNumberWithCountryCode,
      });
    },
    onReset() {
      this.phone = this.student.phone;
    },
  },
  watch: {
    updateUserPhoneNumberStatus(val) {
      if (val == "success") {
        this.$emit("onOk");
      }
    },
  },
};
</script>
<style scoped>
.input-label {
  font-size: 14px;
}
</style>
<style>
.form-group label {
  font-size: 14px;
}
</style>